import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import '../index.css';

const Carousel = ({ slides, ...props }) => {
  return (
    <Splide {...props}>
      {slides.map((item, index) => (
        <SplideSlide key={index}>{item}</SplideSlide>
      ))}
    </Splide>
  );
};

export default Carousel;
