import React from "react";
import OrangeBackground from "../assets/orange.svg";
import PropTypes from "prop-types";

const ReinventVideo = ({
  ReinventVideo_Header,
  ReinventVideo_Paragraph,
  ReinventVideo_VideoSRC,
  ReinventVideo_VideoPoster
}) => {
  
  function whatToShow() {
    if (ReinventVideo_VideoSRC.match(/(\.jpe?g|\.png|\.gif|\.bmp)/g)) {
      return (
        <img className="w-full h-full" src={ReinventVideo_VideoSRC} />
      );
    } if (ReinventVideo_VideoSRC.match(/(\.mp4|\.mov|\.wmv|\.avi)/g)){
      return (
        <video controls poster={ReinventVideo_VideoPoster} className="w-full h-full">
          <source src={ReinventVideo_VideoSRC}/>
          Your browser doesn&apos;t support HTML5 video. Here is a{" "}
          <a href={ReinventVideo_VideoSRC}>link</a> instead
        </video>
      );
    }

    return <></>
  }

  return (
    <div className="w-full flex flex-col items-center bg-gradient-to-b from-[rgba(232,231,231,1)] to-[rgba(232,231,231,0)]">
      <div className="custom-breakpoint-container flex flex-col items-center lg:my-[7.5rem] my-[3.125rem] text-center">
        <h1 className="hidden mb-8 mx-0 md:mx-[2.5rem] lg:mx-0">
          {ReinventVideo_Header}
        </h1>
        <p className="hidden text-[1.1875rem] md:text-[1.5rem] lg:text-[1.25rem] mb-12 mx-0 md:mx-[6rem] lg:mx-0">
          {ReinventVideo_Paragraph}
        </p>
        <div className="w-full flex items-center">
          <div
            style={{ backgroundImage: `url(${OrangeBackground})` }}
            className="flex bg-cover p-1 mx-0 md:mx-[3.875rem] lg:mx-[12.125rem] w-full"
          >
            {whatToShow()}
          </div>
        </div>
      </div>
    </div>
  );
};

ReinventVideo.propTypes = {
  ReinventVideo_Header: PropTypes.string,
  ReinventVideo_Paragraph: PropTypes.string,
  ReinventVideo_VideoSRC: PropTypes.string,
};

export default ReinventVideo;
