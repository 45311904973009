import React from "react";
import banner_background from "../asset/Banner-Background.svg";
import { cache } from "../../eclipse-ux";
import "./SimpleHeroBanner.css";

const SimpleHeroBanner = (props, subText, buttonText, buttonURL) => {
  const siteTracking = cache.getItem("siteTracking");

  const siteTrackingParams = {
    source: (siteTracking && siteTracking.source) || "website",
    medium: (siteTracking && siteTracking.medium) || "button",
    content: (siteTracking && siteTracking.content) || "button",
  };

  const trackingParams = `&source=${siteTrackingParams.source}&medium=${siteTrackingParams.medium}&content=${siteTrackingParams.content}`;

  const paramsToBeReplaced = "&source=website&medium=button&content=button";

  return (
    <div
      className="heroBanner"
      style={{ backgroundImage: `url(${banner_background})` }}
    >
      <div className="heroBanner__container flex-col">
        <div className="heroBanner__titleWrapper">
          <div className="heroBanner__title py-8">
            <div dangerouslySetInnerHTML={{ __html: props.siteCopy }}></div>
            <div className="heroBanner__subtitle">{props.subText}</div>
            {props.buttonURL && (
              <a
                href={props.buttonURL.replace(
                  paramsToBeReplaced,
                  trackingParams
                )}
                className="heroBanner__button hidden md:inline-block"
              >
                {props.buttonText}
              </a>
            )}
          </div>
        </div>
        {props.buttonURL && (
          <div className="pb-8">
            <a
              href={props.buttonURL.replace(paramsToBeReplaced, trackingParams)}
              className="heroBanner__button md:hidden md:mx-0 mr-5"
            >
              {props.buttonText}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimpleHeroBanner;
