import React from "react";
import "../index.css";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const ScrollButton = ({ ...props }) => {
  function scrollUp() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <button className={`__default-scroll-btn transition-all ${props.className}`} onClick={scrollUp}>
      <ArrowUpwardIcon className="fill-inherit transition-all"/>
    </button>
  );
};

export default ScrollButton;
