import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { useMount } from "react-use";
import { siteCopyStore, Routes } from "../app";
import { Button, util } from "../eclipse-ux";
import { TextField } from "@mui/material";
import * as inviteStore from "./model/inviteStore";
import "./index.css";

const InvitePage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();

  const { invitePageCopy, config } = useSelector((state) => state.siteCopy);
  const { authStatus, attempted } = useSelector((state) => state.inviteStore);

  const searchParams = new URLSearchParams(search);
  const pk = searchParams.get("PK");
  const attendeeToken = searchParams.get("attendee_token");
  const campaignCode = searchParams.get("campaign_code");

  if (
    !pk ||
    !pk.hasValue() ||
    !attendeeToken ||
    !attendeeToken.hasValue() ||
    !campaignCode ||
    !campaignCode.hasValue()
  ) {
    window.location.href = config.redirectUrl[language];
  }

  const [email, setEmail] = useState("");

  useMount(async () => {
    await dispatch(siteCopyStore.invite);
  });

  const handleOnChange = (e) => {
    setEmail(e.target.value);
  };

  const handleOnSubmit = async (e) => {
    await dispatch(
      inviteStore.submit({
        attendeeEmail: email,
        pk,
        attendeeToken,
        campaignCode,
      })
    );
    setEmail("");
    
    const cookie = util.getCookie(config.cookieName[language]);
   
    if (cookie !== null) {
      history.push(`${Routes.ROOT}?lang=${language}`);
    }
  };

  const mainText = () => {
    if (invitePageCopy.mainText && !authStatus && !attempted)
      return invitePageCopy.mainText[language];

    if (invitePageCopy.deniedMainText && !authStatus && attempted)
      return invitePageCopy.deniedMainText[language];

    return String.empty;
  };

  const subText = () => {
    if (invitePageCopy.subText && !authStatus && !attempted)
      return invitePageCopy.subText[language];

    if (invitePageCopy.deniedSubText && !authStatus && attempted)
      return invitePageCopy.deniedSubText[language];

    return String.empty;
  };

  const emailFieldPlaceholderText = () => {
    if (invitePageCopy.emailFieldPlaceholderText && !authStatus && !attempted)
      return invitePageCopy.emailFieldPlaceholderText[language];

    if (
      invitePageCopy.deniedEmailFieldPlaceholderText &&
      !authStatus &&
      attempted
    )
      return invitePageCopy.deniedEmailFieldPlaceholderText[language];

    return String.empty;
  };

  const submitButtonText = () => {
    if (invitePageCopy.submitButtonText && !authStatus && !attempted)
      return invitePageCopy.submitButtonText[language];

    if (
      invitePageCopy.deniedEmailFieldPlaceholderText &&
      !authStatus &&
      attempted
    )
      return invitePageCopy.deniedSubmitButtonText[language];

    return String.empty;
  };

  return (
    <div className="sap-bg-cover bg-cover h-full flex flex-col items-center md:pt-28 pt-14">
      <div className="bg-white flex flex-col items-center md:py-5 md:px-28 px-5 py-10 shadow-xl mx-6 mb-12">
        <h1 className="font-normal md:text-[2.197rem] text-[1.44rem]">
          {mainText()}
        </h1>
        <p className="invite-sub-text md:w-[29.75rem] md:mb-[2.688rem] mb-[1.438rem]  mb-leading-6 md:text-center font-normal text-base text-sap-black-545454" dangerouslySetInnerHTML={{__html: subText()}}>
        </p>
        <TextField
          variant="outlined"
          value={email}
          onChange={handleOnChange}
          placeholder={emailFieldPlaceholderText()}
          className="md:mb-10 mb-5 email-textfield shadow"
          size="small"
          fullWidth
        />
        <Button
          onClick={handleOnSubmit}
          className="w-[10.875rem] h-[2.4375rem] md:mb-14 mb-12 border-solid border-sap-blue-037DB9 text-sap-blue-037DB9 border-2 rounded-[2.625rem] font-normal text-base"
        >
          {submitButtonText()}
        </Button>
      </div>
    </div>
  );
};

export default InvitePage;
export { inviteStore };
