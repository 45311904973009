import React from "react";
import {
  Route,
  BrowserRouter,
  Switch,
  useParams,
  useLocation,
} from "react-router-dom";
import { Routes } from "..";
import HomePage from "../../home";
import AgendaPage from "../../agenda";
import LogisticsPage from "../../logistics";
import PartnersPage from "../../partners";
import ContentPage from "../../content";
import EventExperiencePage from "../../event-experience";
import CelebrationNightPage from "../../celebration-night";
import MobileAppLandingPage from "../../mobile-app-landing";
import InvitePage from "../../invite";
import { NotFound } from "../../eclipse-ux";
import withAppComposer from "../hook/withAppComposer";

const SiteMap = (props) => {
  const { match } = props;
  
  let { eventName } = useParams();
 
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  let lang = searchParams.get("lang");

  if (!lang) {
    lang = "default";
  }
 
  return (
    <BrowserRouter basename={match.url}>
      <Switch>
      <Route
          exact
          path={Routes.CELEBRATION}
          component={() => {
            return withAppComposer(CelebrationNightPage)({
              eventName,
              lang,
              route: Routes.CELEBRATION,
            });
          }}
        />
      <Route
          exact
          path={Routes.VENUE_FAQ}
          component={() => {
            return withAppComposer(LogisticsPage)({
              eventName,
              lang,
              route: Routes.VENUE_FAQ,
            });
          }}
        />
        <Route
          exact
          path={Routes.EVENT_EXPERIENCE}
          component={() => {
            return withAppComposer(EventExperiencePage)({
              eventName,
              lang,
              route: Routes.EVENT_EXPERIENCE,
            });
          }}
        />
        <Route
          exact
          path={Routes.CONTENT}
          component={() => {
            return withAppComposer(ContentPage)({
              eventName,
              lang,
              route: Routes.CONTENT,
            });
          }}
        />
        <Route
          exact
          path={Routes.PARTNERS}
          component={() => {
            return withAppComposer(PartnersPage)({
              eventName,
              lang,
              route: Routes.PARTNERS,
            });
          }}
        />
        <Route
          exact
          path={Routes.AGENDA}
          component={() => {
            return withAppComposer(AgendaPage)({
              eventName,
              lang,
              route: Routes.AGENDA,
            });
          }}
        />
         <Route
          exact
          path={Routes.INVITE}
          component={() => {
            return withAppComposer(InvitePage)({
              eventName,
              lang,
              route: Routes.INVITE,
            });
          }}
        />
        <Route
          exact
          path={Routes.MOBILE_APP_LANDING}
          component={() => {
            return withAppComposer(MobileAppLandingPage)({
              eventName,
              lang,
              route: Routes.MOBILE_APP_LANDING,
            });
          }}
        />
        <Route
          exact
          path={Routes.ROOT}
          component={() => {
            return withAppComposer(HomePage)({
              eventName,
              lang,
              route: Routes.ROOT,
            });
          }}
        />
        <Route path="*" component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default SiteMap;
