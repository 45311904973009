import React from "react";
import Button from "@mui/material/Button";
import "./dataCard.css";
import largeStock from "../../assets/282355_concur.airport_0987.jpg";
const dataCard = ({


  registrationPolicy_registrationPolicyRates,
  registrationPolicy_registrationPolicyRatesValues,
  registrationPolicy_registrationPolicyTitle,
  registrationPolicy_registrationPolicyDescription,
  registrationPolicy_registrationPolicyBtn,
  registrationPolicy_registrationPolicyBtnURL,
  registrationPolicy_internationalAttendeesRegistrationBtnURL,
  registrationPolicy_internationalAttendeesTitle,
  registrationPolicy_internationalAttendeesDescription,
  registrationPolicy_internationalAttendeesRegistrationBtn,
}) => {
  return (
    <>
      <section className="sap__card gradientB">
        <div className="sap__cardBody  xl:flex-row-reverse">
          <div className="sap__cardImageWrapper sap__cardImageWrapper--large ">
            <img src={largeStock} alt="" className="sap__cardImage" />
          </div>

          <div className="sap__cardWrapper">
          <div className="sap__cardTitle">
              {registrationPolicy_registrationPolicyRates}
            </div>
            <div className="sap__cardCopy" dangerouslySetInnerHTML={{ __html: registrationPolicy_registrationPolicyRatesValues }}>
              {/* <p>{registrationPolicy_registrationPolicyRatesValues}</p> */}
            </div>



            <div className="sap__cardTitle">
              {registrationPolicy_registrationPolicyTitle}
            </div>
            <div className="sap__cardCopy">
            <p dangerouslySetInnerHTML={{__html: registrationPolicy_registrationPolicyDescription}}></p>
            
            </div>
            <div className="sap__cardButtonWRapper ">
              {registrationPolicy_registrationPolicyBtn &&
              <Button
                className="sap-btn sap__cardBtn  hidden md:flex"
                onClick={() =>
                  window.open(
                    registrationPolicy_registrationPolicyBtnURL,
                    "_blank"
                  )
                }
              >
                {registrationPolicy_registrationPolicyBtn}
              </Button>}
              <div className=" md:hidden block md:mb-0 ">
                <a
                  href={registrationPolicy_registrationPolicyBtnURL}
                  target="_blank"
                  className="sap__link  "
                >
                  {registrationPolicy_registrationPolicyBtn}
                </a>
              </div>
            </div>

            <div className="sap__cardTitle 2xl:mt-0 xl:mt-[30px]">
              {registrationPolicy_internationalAttendeesTitle}
            </div>
            <div className="sap__cardCopy">
            <p dangerouslySetInnerHTML={{ __html: registrationPolicy_internationalAttendeesDescription}}></p>
            </div>
            <div className="sap__cardButtonWRapper ">
              {registrationPolicy_internationalAttendeesRegistrationBtn &&
              <Button
                className="sap-btn sap__cardBtn  hidden md:flex"
                onClick={() =>
                  (window.location.href =
                    registrationPolicy_internationalAttendeesRegistrationBtnURL)
                }
              >
                {registrationPolicy_internationalAttendeesRegistrationBtn}
              </Button>}
              <div className=" md:hidden block md:mb-0 ">
                <a
                  href={
                    registrationPolicy_internationalAttendeesRegistrationBtnURL
                  }
                  className="sap__link  "
                >
                  {registrationPolicy_internationalAttendeesRegistrationBtn}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default dataCard;
