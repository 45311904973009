import React from "react";
import Photo1 from "../assets/exp1.png";
import Photo2 from "../assets/exp2.png";
import Photo3 from "../assets/exp3.png";
import Photo1D from "../assets/exp1d.png";
import Photo2D from "../assets/exp2d.png";
import Photo3D from "../assets/exp3d.png";

const ExperienceSubCard = ({ siteCopy, language, index }) => { 
  return (
    <div className="flex flex-col lg:flex-row">
      <img className="w-full block lg:hidden" src={index === 0 ? Photo1 : index === 1 ? Photo2 : index === 2 ? Photo3 : ""} />
      <img width={555} className="hidden lg:block" src={index === 0 ? Photo1D : index === 1 ? Photo2D : index === 2 ? Photo3D : ""} />
    <div className="flex flex-col space-y-[2.5rem] bg-white py-7 px-5 md:px-10 md:pb-14 lg:py-[3.75rem] lg:px-[4.313em]">
      <p
        dangerouslySetInnerHTML={{ __html: siteCopy.header[language] }}
        className="
           text-[1rem] leading-[1.5rem] 
           md:text-[1.25rem] md:leading-[1.9375rem]"
      ></p>
      <div className="flex flex-col">
        {siteCopy &&
          siteCopy.body.map((data, index, arr) => {
            if (data.p1[language] !== undefined && data.p1[language] !== "") {
              return (
                <div key={index} className="">
                  <p className="text-[1.25rem] leading-[1.556rem]">
                    {data.p1[language]}
                  </p>
                  <p className="text-xs leading-[1.15rem] mt-2">
                    {data.p2[language]}
                  </p>
                  <p className="text-base leading-[1.5rem] text-[#545454]">
                    {data.p3[language]}
                  </p>
                  {(!(index + 1 === arr.length) && siteCopy.body[index + 1] && siteCopy.body[index + 1].p1[language]) && <hr className="my-5 bg-[#DDDDDD] border-none h-px"></hr>}
                </div>
              );
            }
          })}
      </div>
    </div>
    </div>
  );
};

export default ExperienceSubCard;
