const EWC_CACHE = "EWC_CACHE";
const TWO_WEEKS = 1000 * 60 * 60 * 24 * 14;

const currentTime = () => {
  return Date.now();
};

export const get = () => {
  let cache = {
    data: {},
    nextCleanup: new Date().getTime() + TWO_WEEKS,
  };

  try {
    const data = localStorage.getItem(EWC_CACHE);

    if (data) {
      cache = JSON.parse(data);
    }
  } catch (e) {
    console.error(e.message);
  }

  return cache;
};

export const del = () => {
  localStorage.clear();
}

export const getItem = (key) => {
  const cache = get();

  return cache.data[key];
};

export const setItem = (key, value) => {
  const cache = get();
  const data = cache.data;

  const item = {
    cacheKey: key,
    cacheExpiry: new Date().getTime() + TWO_WEEKS,
    ...value,
  };

  data[key] = item;

  try {
    localStorage.setItem(EWC_CACHE, JSON.stringify(cache));
  } catch (e) {
    invalidate(data);
  }
};

const invalidate = (data) => {
  let isDeleted;
  let oldest;
  let oldestKey;

  //if 14 days have been passed, it removes the cache
  for (const key in data) {
    const expiry = data[key].cacheExpiry;
    if (expiry && expiry <= currentTime()) {
      delete data[key];
      isDeleted = true;
    }

    //finding the oldest cache in case none of them are expired
    if (!oldest || oldest > expiry) {
      oldest = expiry;
      oldestKey = key;
    }
  }

  //remove the oldest cache if there is no more space in local storage (5 MB)
  if (!isDeleted && oldestKey) {
    delete data[oldestKey];
  }

  localStorage.setItem(
    EWC_CACHE,
    JSON.stringify({
      data,
      nextCleanup: currentTime() + TWO_WEEKS,
    })
  );
};
