import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { siteCopyStore } from "../../../app";
import "../../index.css";
import { MenuList, MenuItem, Popper, Button } from "@mui/material";

const LanguageSwitcher = ({ lang = "en", langItems, ...props }) => {
  const dispatch = useDispatch();
  const { config, language } = useSelector((state) => state.siteCopy);

  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  /*this is mega hacky, but allows for more than one togglable site */
  function buttonLanguage() {
    let response = '';
    switch (lang) {
      case "en":
        response = config.languageEnglish[lang];
        break;
      case "es":
        response = config.languageSpanish["es-Madrid"];
        break;
      case "de":
        response = config.languageGerman["de-Zurich"];
        break;
      case "ja":
        response = config.languageJapanese["ja-Tokyo"]
        break;
    }

    return response
  }

  const handleToggle = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  function langSelecHandler(value, label) {
    dispatch(siteCopyStore.setLanguageLabel(label));

    window.location.href = `${window.location.href.split("?")[0]
      }?lang=${value}`;
  }

  const url = window.location.pathname;
  const newRegex = new RegExp(props.regex);

  if (newRegex.test(url) != true) return <></>;

  let items = langItems;

  items = items.map((x) => {
    x.default = window.location.search.includes(`lang=${x.value}`);
    return x;
  });
    return (
      <div
        id="langMenuButton"
        className={`absolute ${props.className ? props.className : ""}`}
      >
        <Button
          className={`bg-black text-white normal-case
                w-full h-full 
                rounded-none
                pl-[0.875rem] pr-[3.25rem] pt-[0.5625rem] pb-[0.4375rem]
                ${props.buttonClassNames ? props.buttonClassNames : ""} `}
          onClick={handleToggle}
        >
          {props.icon && (
            <img
              className="w-[1.5625rem] h-[1.5625rem] mr-[0.625rem]"
              src={props.icon}
            ></img>
          )}
          {buttonLanguage()}
          {/*language !== String.empty
            ? language
            : this needs to change  config.languageSpanish[lang]*/}
        </Button>

        <Popper
          open={open}
          {...props}
          placement="bottom-start"
          anchorEl={anchorEl}
        >
          <MenuList
            id="langMenu"
          //className={`flex flex-col items-center bg-white w-[11.25rem] ${props.menuListStyles ? props.menuListStyles: ''}`}
          >
            {items.map((x, index) => {
              return (
                <MenuItem
                  onClick={() => langSelecHandler(x.value, x.label)}
                  //className={`w-full justify-center ${props.menuItemStyles? props.menuItemStyles : ''}`}
                  key={index}
                >
                  {x.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </Popper>
      </div>
    );
  };

export default LanguageSwitcher;
