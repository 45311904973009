import React from "react";
import PropTypes from "prop-types";
import orangeBackground from "../assets/orangeBackground.svg";
import FloorImg from "../assets/showFloorImg.jpg";
import { Button } from "@mui/material";

const ShowFloor = ({
  ShowFloor_Header,
  ShowFloor_Paragraph,
  ShowFloor_ButtonText,
  ShowFloor_ButtonURL,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${orangeBackground})` }}
      className="w-full flex flex-col items-center bg-cover bg-center"
    >
      <div className="flex custom-breakpoint-container">
        <div
          className="
      my-[3.125rem]
      md:my-[4.375rem]
      xl:my-[7.5rem]
      flex
      flex-col
      xl:flex-row
      items-center
      bg-sap-white-FFFFFF
      shadow-[0_1.1875rem_2.375rem_#00000029]"
        >
          <div className="flex">
            <img
              className="w-full h-full"
              alt="show floor image"
              src={FloorImg}
            ></img>
          </div>
          <div
            className="lg:ml-[4.3125rem] lg:mr-[7.1875rem] 
            px-[0.625rem]
            md:px-[2.625rem]
            xl:px-0"
          >
            <div className="text-[1.44rem] md:text-[2.1969rem] my-[1.25rem] md:my-[1.875rem]">
              {ShowFloor_Header}
            </div>
            <p className="mb-[1.875rem] text-[1rem] leading-[1.5rem]">
              {ShowFloor_Paragraph}
            </p>
            {ShowFloor_ButtonText && (
              <>
                <a
                  className="mb-[1.875rem] md:mb-[3.75rem] md:w-[16.5rem] md:whitespace-nowrap lg:w-[16.1875rem] sap-btn hidden md:flex no-underline items-center"
                  href={ShowFloor_ButtonURL}
                >
                  {ShowFloor_ButtonText}
                </a>
                <a
                  className="flex text-[1rem] leading-[1.5rem] text-[#037DB9] md:hidden no-underline font-light w-fit pb-8"
                  href={ShowFloor_ButtonURL}
                >
                  {ShowFloor_ButtonText}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ShowFloor.propTypes = {
  ShowFloor_Header: PropTypes.string,
  ShowFloor_Paragraph: PropTypes.string,
  ShowFloor_ButtonText: PropTypes.string,
  ShowFloor_ButtonURL: PropTypes.string,
};

export default ShowFloor;
