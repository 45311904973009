import React from "react";
import { BasicNavBar, LanguageSwitcher, LanguageSwitcherMobile, cache } from "../../../eclipse-ux";
import { NavLink, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useSelector } from "react-redux";
import { Routes } from "../..";
import Button from "../../../eclipse-ux/component/Button";
import sapBanner from "../../asset/sapBannerPiece.svg";
import EmailIcon from "../../asset/email.svg";
import QuestionIcon from "../../asset/question.svg";
import LanguageIcon from "../../asset/languageIcon.svg";
import LanguageIconBlack from "../../asset/languageIconBlack.svg";
import langSwitcherItems from "../langShwitcherItemGenerator";
import "./Navbar.css";

const Navbar = ({ language = "en", pk, attendeeToken, campaignCode }) => {
  const { config } = useSelector((state) => state.siteCopy);
  const { navbarCopy } = useSelector((state) => state.siteCopy);
  const showEventExperience =
    navbarCopy.eventExperienceLink && language === "en";
  let lang = "";
  if (language && typeof language === "string") lang = language.split("-")[0];

  const location = useLocation();
  //this is used by the lang switcher to choose when to display
  const langRegex =
    /sapsapphire\/madrid|sapsapphire\/zurich|sapsapphire\/tokyo/gi;
  const langItems = langSwitcherItems(language, config);

  const siteTracking = cache.getItem("siteTracking"); 

  const siteTrackingParams = {
    source : siteTracking && siteTracking.source || "website",
    medium: siteTracking && siteTracking.medium || "button",
    content : siteTracking && siteTracking.content || "button"
  }

  const trackingParams = `&source=${siteTrackingParams.source}&medium=${siteTrackingParams.medium}&content=${siteTrackingParams.content}`;

  const paramsToBeReplaced = "&source=website&medium=button&content=button";


  const logoTray = (
    navbarCopy.logo && navbarCopy.logo[language] && <NavLink to={`${Routes.ROOT}?lang=${lang}`}>
      <img
        className="hidden desktop:block h-[42.73px] my-[0.5625rem]"
        alt="SAP Sapphire"
        src={navbarCopy.logoDesktop[language]}
      />
      <img
        className="h-[2.25rem] md:h-[3.4375rem] my-[0.5625rem] desktop:hidden"
        alt="SAP Sapphire"
        src={navbarCopy.logo[language]}
      />
    </NavLink>
  );

  const linkTray = (
    <div className="flex space-x-6">
      <NavLink
        dir="auto"
        className={`sap-nav-link pb-3 ${location.pathname == Routes.AGENDA ? "currentUrl " : ""
          } `}
        to={`${Routes.AGENDA}?lang=${lang}`}
      >
        {navbarCopy.agendaLink && navbarCopy.agendaLink[language]}
      </NavLink>
      <NavLink
        dir="auto"
        className={`sap-nav-link pb-3 ${location.pathname == Routes.CONTENT ? "currentUrl " : ""
          } `}
        to={`${Routes.CONTENT}?lang=${lang}`}
      >
        {navbarCopy.contentLink && navbarCopy.contentLink[language]}
      </NavLink>
      {showEventExperience && (
        <NavLink
          dir="auto"
          className={`sap-nav-link pb-3 ${location.pathname == Routes.EVENT_EXPERIENCE ? "currentUrl " : ""
            } `}
          to={`${Routes.EVENT_EXPERIENCE}?lang=${lang}`}
        >
          {navbarCopy.eventExperienceLink[language]}
        </NavLink>
      )}
      {navbarCopy && navbarCopy.partnerShowcaseLink && navbarCopy.partnerShowcaseLink[language] && (
        <a
          dir="auto"
          className="sap-nav-link pb-3"
          href={navbarCopy.partnerShowcaseLinkURL[language].replace(paramsToBeReplaced,trackingParams)}
        >
          {navbarCopy.partnerShowcaseLink[language]}
        </a>
      )}
      {navbarCopy && navbarCopy.celebrationNightLink && navbarCopy.celebrationNightLink[language] && (
        <NavLink
        dir="auto"
        className={`sap-nav-link pb-3 ${location.pathname == Routes.CELEBRATION ? "currentUrl " : ""
          } `}
        to={`${Routes.CELEBRATION}?lang=${lang}`}
      >
        {navbarCopy.celebrationNightLink[language]}
      </NavLink>
      )}
      <NavLink
        dir="auto"
        className={`sap-nav-link pb-3 ${location.pathname == Routes.VENUE_FAQ ? "currentUrl " : ""
          } `}
        to={`${Routes.VENUE_FAQ}?lang=${lang}`}
      >
        {navbarCopy.venueFaqLink && navbarCopy.venueFaqLink[language]}
      </NavLink>
    </div>
  );
// DC-874
const actionTray = (
  <div className="flex items-center lg:mt-[2.625rem]">
    {navbarCopy && navbarCopy.emailUrl && navbarCopy.emailUrl[language] && (
    <a href={navbarCopy.emailUrl && navbarCopy.emailUrl[language]}>
      <img
        src={EmailIcon}
        className="w-[1.875rem] h-[1.5625rem] mr-[0.9375rem]"
      ></img>
    </a>)}
{/* end of ticket */}
      {/* the question icon */}
      <HashLink to={`${Routes.VENUE_FAQ}?lang=${lang}#questions`}>
        <img
          src={QuestionIcon}
          className="w-[1.875rem] h-[1.5625rem] mr-[0.9375rem]"
        ></img>
      </HashLink>
      {navbarCopy.registerButton && navbarCopy.registerButton[language] && (
        <Button
          className=" flex mr-[0.625rem] mb-[1.25rem] md:mb-[0rem] sap-btn
        "
          onClick={() =>
            (window.location.href = `${navbarCopy.registerButtonUrl[language].replace(paramsToBeReplaced,trackingParams)}`)
          }
        >
          {navbarCopy.registerButton[language]}
        </Button>
      )}
      <img className="w-[4.875rem] h-[2.5rem] ml-8" src={sapBanner}></img>
    </div>
  );

  const drawerTray = (
    <div className="flex">
      {navbarCopy.registerButton && navbarCopy.registerButton[language] && <Button
        className="flex mr-[0.625rem]  md:mb-[0rem] w-[13.5rem] h-[2.4375rem] border-solid border-[#037DB9] border-2 rounded-[2.625rem]"
        onClick={() =>
          (window.location.href = `${navbarCopy.registerButtonUrl[language].replace(paramsToBeReplaced,trackingParams)}`)
        }
      >
        {navbarCopy.registerButton[language]}
      </Button>}
      <img className="w-[4.875rem] h-[2.5rem] ml-8" src={sapBanner}></img>
    </div>
  );

  const mobileTray = (
    <div>
      <img className="w-[4.875rem] h-[2.5rem] ml-8" src={sapBanner}></img>
    </div>
  );

  const drawerMenu = (
    <div className="flex flex-col pl-[1.875rem] pr-[2.5rem] pt-[1.625rem]">
      {navbarCopy.registerButton && navbarCopy.registerButton[language] && (
        <Button
          className="flex md:hidden mb-[1.25rem] md:mb-[0rem] w-[13.5rem] h-[2.4375rem] border-solid border-[#037DB9] border-2 rounded-[2.625rem]"
          onClick={() =>
            (window.location.href = `${navbarCopy.registerButtonUrl[language].replace(paramsToBeReplaced,trackingParams)}`)
          }
        >
          {navbarCopy.registerButton[language]}
        </Button>
      )}

      {config.languageSpanish && (
        <LanguageSwitcherMobile
          lang={lang}
          language={language}
          regex={langRegex}
          langItems={langItems}
          className={"flex flex-col relative lg:hidden text-sap-gray-4B4B4B"}
          buttonClassNames={
            "pl-[0rem] pt-[0rem] pb-[1rem] justify-start bg-white text-sap-gray-4B4B4B text-[1rem] leading-[1.625rem] font-normal"
          }
          menuListStyles={"justify-start py-0 pl-[2.1875rem] hover:bg-inherit"}
          menuItemStyles={"p-0 py-[0.5625rem] hover:bg-inherit"}
          icon={LanguageIconBlack}
        />
      )}

      <NavLink
        dir="auto"
        className="sap-nav-link border-x-0 border-y-[0.0625rem] border-solid border-sap-gray-4B4B4B py-[16px]"
        to={`${Routes.ROOT}?lang=${lang}`}
      >
        {navbarCopy.homeLink && navbarCopy.homeLink[language]}
      </NavLink>

      <NavLink
        dir="auto"
        className="sap-nav-link border-x-0 border-t-0 border-y-[0.0625rem] border-solid border-sap-gray-4B4B4B py-[16px]"
        to={`${Routes.AGENDA}?lang=${lang}`}
      >
        {navbarCopy.agendaLink && navbarCopy.agendaLink[language]}
      </NavLink>
      <NavLink
        dir="auto"
        className="sap-nav-link border-x-0 border-t-0 border-b-[0.063rem] border-solid border-sap-gray-4B4B4B py-[16px]"
        to={`${Routes.CONTENT}?lang=${lang}`}
      >
        {navbarCopy.contentLink && navbarCopy.contentLink[language]}
      </NavLink>
      {showEventExperience && navbarCopy.eventExperienceLink[language] &&(
        <NavLink
          dir="auto"
          className="sap-nav-link border-x-0 border-t-0 border-b-[0.063rem] border-solid border-sap-gray-4B4B4B py-[16px]"
          to={`${Routes.EVENT_EXPERIENCE}?lang=${lang}`}
        >
          {navbarCopy.eventExperienceLink[language]}
        </NavLink>
      )}
      {navbarCopy && navbarCopy.partnerShowcaseLink && navbarCopy.partnerShowcaseLink[language] && (
        <a
          dir="auto"
          className="sap-nav-link border-x-0 border-t-0 border-b-[0.063rem] border-solid border-sap-gray-4B4B4B py-[16px]"
          href={navbarCopy.partnerShowcaseLinkURL[language]}
        >
          {navbarCopy.partnerShowcaseLink[language]}
        </a>
      )}
       {navbarCopy && navbarCopy.celebrationNightLink && navbarCopy.celebrationNightLink[language] && (
        <NavLink
        dir="auto"
        className="sap-nav-link border-x-0 border-t-0 border-b-[0.063rem] border-solid border-sap-gray-4B4B4B py-[16px]"
        to={`${Routes.CELEBRATION}?lang=${lang}`}
      >
        {navbarCopy.celebrationNightLink[language]}
      </NavLink>
      )}
      <NavLink
        dir="auto"
        className="sap-nav-link border-x-0 border-t-0 border-b-[0.063rem] border-solid border-sap-gray-4B4B4B py-[16px]"
        to={`${Routes.VENUE_FAQ}?lang=${lang}`}
      >
        {navbarCopy.venueFaqLink && navbarCopy.venueFaqLink[language]}
      </NavLink>

      <div className="flex flex-row items-center sap-nav-link border-0 py-[16px]">
        {navbarCopy && navbarCopy.emailUrl && navbarCopy.emailUrl[language] &&(
        <a
          className="leading-[0rem]"
          href={navbarCopy.emailUrl && navbarCopy.emailUrl[language]}
        >
          <img
            src={EmailIcon}
            className="w-[1.875rem] h-[1.5625rem] mr-[2.1875rem]"
          ></img>
        </a>
        )}
        {/* the question icon */}
        <HashLink
          className="leading-[0rem]"
          to={`${Routes.VENUE_FAQ}?lang=${lang}#questions`}
        >
          <img src={QuestionIcon} className="w-[1.875rem] h-[1.5625rem]"></img>
        </HashLink>
      </div>
    </div>
  );

  return (
    <>
    <BasicNavBar
      logoTray={logoTray}
      linkTray={linkTray}
      actionTray={actionTray}
      drawerWidth={300}
      drawerTray={drawerTray}
      drawerMenu={drawerMenu}
      mobileTray={mobileTray}
      languageSwitcher={
        config.languageSpanish && (
          <LanguageSwitcher
            lang={lang}
            language={language}
            regex={langRegex}
            langItems={langItems}
            className="hidden md:flex md:top-4 desktop:top-0 right-[5%]"
            icon={LanguageIcon}
          />
        )
      }
      className="bg-sap-white-FFFFFF flex items-center shadow-xl"
    />
    {navbarCopy.registerButton && navbarCopy.registerButton[language] && (
      <>
      <div className="fixed top-[3.5rem] z-50">
        <Button
          className="tablet:hidden w-screen h-[2.4375rem] bg-[#037DB9] text-white"
          onClick={() =>
            (window.location.href = `${navbarCopy.registerButtonUrl[language].replace(paramsToBeReplaced,trackingParams)}`)
          }
        >
          {navbarCopy.registerButton[language]}
        </Button>
        </div>
        <div className="mt-[2.4rem] tablet:mt-0"></div>
        </>
      )}
    </>
  );
};

export default Navbar;
