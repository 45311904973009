import React from "react";
import "./contactUs.css";

const contactUs = ({
  contactUs_title,
  contactUs_card1Title,
  contactUs_card1Email,
  contactUs_card1EmailUrl,
  contactUs_card1hoursDescription,
  contactUs_card1OperatingHours,
  contactUs_card1OperatingDays,
  contactUs_card2Title,
  contactUs_card2Email,
  contactUs_card2EmailUrl,
  contactUs_card2hoursDescription,
  contactUs_card2OperatingHours,
  contactUs_card2OperatingDays,
  contactUs_card3Title,
  contactUs_card3Email,
  contactUs_card3EmailUrl,
  contactUs_card3PhoneNumber,
  contactUs_card3IntPhoneNumber,
  contactUs_card3hoursDescription,
  contactUs_card3OperatingHours,
  contactUs_card3OperatingDays,
}) => {
  return (
    <>
      <section className="goldGradient xl:h-[42.3125rem] flex  content-center flex-col">
        <div
          className="
        text-center


        xl:text-[2.8563rem]
        md:text-[2.8563rem]
        text-[2.1969rem]


        xl:mb-[3.75rem]
        md:mb-[2.5rem]
        mb-[2.5rem]


        xl:mt-[7.5rem]
        lg:mt-[4.375rem]
        md:mt-[4.375rem]
        mt-[3.125rem]
     
        
        "
        >
          {contactUs_title}
        </div>

        <div className="flex justify-center xl:flex-row lg:flex-col flex-col md:mb-[4.375rem] mb-[1.875rem] px-0">
          {(contactUs_card1Title ||
            contactUs_card1EmailUrl ||
            contactUs_card1hoursDescription ||
            contactUs_card1OperatingHours ||
            contactUs_card1OperatingDays) && (
            <div className="contactCard">
              <div className="contactCard__title">{contactUs_card1Title}</div>
              <div className="contactCard__body">
                <div className="contactCard__email">
                  <a href={contactUs_card1EmailUrl} className="sap__link">
                    {contactUs_card1Email}
                  </a>
                </div>
                <div>
                  <div>{contactUs_card1hoursDescription}</div>
                  <div>{contactUs_card1OperatingHours}</div>
                  <div>{contactUs_card1OperatingDays}</div>
                </div>
              </div>
            </div>
          )}

          {(contactUs_card2Title ||
            contactUs_card2EmailUrl ||
            contactUs_card2hoursDescription ||
            contactUs_card2OperatingHours ||
            contactUs_card2OperatingDays) && (
            <div className="contactCard">
              <div className="contactCard__title">{contactUs_card2Title}</div>
              <div className="contactCard__body ">
                <div className="contactCard__email">
                  <a href={contactUs_card2EmailUrl} className="sap__link">
                    {contactUs_card2Email}
                  </a>
                </div>
                <div>
                  <div>{contactUs_card2hoursDescription}</div>
                  <div>{contactUs_card2OperatingHours}</div>
                  <div>{contactUs_card2OperatingDays}</div>
                </div>
              </div>
            </div>
          )}

          {(contactUs_card3Title ||
            contactUs_card3EmailUrl ||
            contactUs_card3PhoneNumber ||
            contactUs_card3IntPhoneNumber ||
            contactUs_card3hoursDescription ||
            contactUs_card3OperatingHours ||
            contactUs_card3OperatingDays) && (
            <div className="contactCard">
              <div className="contactCard__title">{contactUs_card3Title}</div>
              <div className="contactCard__body ">
                <div className="contactCard__email">
                  <a href={contactUs_card3EmailUrl} className="sap__link">
                    {contactUs_card3Email}
                  </a>
                </div>
                <div>
                  <div>{contactUs_card3PhoneNumber}</div>
                  <div>{contactUs_card3IntPhoneNumber}</div>
                  <div>{contactUs_card3hoursDescription}</div>
                  <div>{contactUs_card3OperatingHours}</div>
                  <div>{contactUs_card3OperatingDays}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default contactUs;
