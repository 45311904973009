import React from "react";
import thingsToDoImg1 from "../assets/thingsToDo1.jpg";
import thingsToDoImg2 from "../assets/thingsToDo2.jpg";
import thingsToDoImg3 from "../assets/thingsToDo3.jpg";
import thingsToDoImg4 from "../assets/thingsToDo4.jpg";
import thingsToDoImg5 from "../assets/thingsToDo5.jpg";
import thingsToDoImg6 from "../assets/thingsToDo6.jpg";
import thingsToDoImg7 from "../assets/thingsToDo7.jpg";
import thingsToDoImg8 from "../assets/thingsToDo8.jpg";
import thingsToDoImg9 from "../assets/thingsToDo9.png";

import "./ThingsToDo.css";

import PropTypes from "prop-types";

const ThingsToDo = ({
  mainText,
  subText,
  card1Title,
  card1description1,
  card1description2,
  card2Title,
  card2Description1,
  card3Title,
  card3Description1,
  card4Title,
  card4Description1,
  card5Title,
  card5Description1,
  card6Title,
  card6Description1,
  card6Description2,
  card7Title,
  card7Description1,
  card8Title,
  card8Description1,
  card9Title,
  card9Description1,
}) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className=" my-[3.125rem] md:my-[4.375rem] lg:my-[7.5rem]
       w-full 
       flex flex-col items-center  
       custom-breakpoint-container"
      >
        <h1 className="text-center mb-[1.25rem] mt-0 md:text-[46px] md:leading-[55px]">
          {" "}
          {mainText}
        </h1>
        <p
          className="text-center mb-[2.5rem] lg:mb-[5.625rem]
        text-[1.1875rem] leading-[1.75rem] md:text-[1.25rem] md:leading-[1.9375rem] lg:text-[1.1875rem] lg:leading-[1.75rem]
        "
        >
          {subText}
        </p>
        <div className="flex flex-row flex-wrap justify-center w-full md:gap-x-[1.25rem]">
          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg1}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card1Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card1description1 }}
              ></p>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card1description2 }}
              ></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg2}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card2Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card2Description1 }}
              ></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg3}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card3Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card3Description1 }}
              ></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg4}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card4Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card4Description1 }}
              ></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg5}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card5Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card5Description1 }}
              ></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg6}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card6Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card6Description1 }}
              ></p>
              <p dangerouslySetInnerHTML={{ __html: card6Description2 }}></p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg7}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card7Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card7Description1 }}
              >
              </p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src={thingsToDoImg8}
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card8Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card8Description1 }}
              >
              </p>
            </div>
          </div>

          <div className="ttd ">
            <div>
              <img
                className="ttd__image"
                src="https://go4.events.sap.com/homeHero2.png"
                alt="image for card"
              ></img>
            </div>
            <div className="ttd__wrapper">
              <div className="ttd__title">{card9Title}</div>
              <p
                className="
              text-[1rem] leading-[1.5rem] lg:text-[1.25rem] lg:leading-[1.9375rem]
              "
                dangerouslySetInnerHTML={{ __html: card9Description1 }}
              >
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ThingsToDo.propTypes = {
  mainText: PropTypes.string,
  subText: PropTypes.string,
  card1Title: PropTypes.string,
  card1description1: PropTypes.string,
  card1description2: PropTypes.string,
  card2Title: PropTypes.string,
  card2Description1: PropTypes.string,
  card2Description2Item1: PropTypes.string,
  card2Description2Item2: PropTypes.string,
  card2Description2Item3: PropTypes.string,
  card2Description2Item4: PropTypes.string,
  card2Description2Item5: PropTypes.string,
  card2Description2Item6: PropTypes.string,
  card3Title: PropTypes.string,
  card3Description1: PropTypes.string,
  card4Title: PropTypes.string,
  card4Description1: PropTypes.string,
  card5Title: PropTypes.string,
  card5Description1: PropTypes.string,
  card6Title: PropTypes.string,
  card6Description1: PropTypes.string,
  card6Description2: PropTypes.string,
  card7Title: PropTypes.string,
  card7Description1: PropTypes.string,
  card8Title: PropTypes.string,
  card8Description1: PropTypes.string,
  card9Title: PropTypes.string,
  card9Description1: PropTypes.string,
};

export default ThingsToDo;
