import React from "react";
import ReactDOM from "react-dom";
import { App } from "./app";
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import reportWebVitals from "./reportWebVitals";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import "./eclipse-ux/util/extend.js";

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <StyledEngineProvider injectFirst>
      <BrowserRouter>
        <Switch>
          <Route path="/sapsapphire" component={App} />
          <Route
            component={() => {
              if (window.location.href.indexOf("mobile-app") > -1) {
                window.location.href = "/sapsapphire/orlando/mobile-app";
              } else {
                window.location.href =
                  "https://www.sap.com/about/events/sapphire.html";
              }

              return null;
            }}
          />
        </Switch>
      </BrowserRouter>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
