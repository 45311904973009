import React from "react";
import Button from "@mui/material/Button";
import "./largeDataCard.css";
import imageH from "../../assets/saph_1.png";

const largeDataCard = ({
  venue_mainText,
  venue_photoUrl,
  venue_venueDirectionsBtn,
  venue_venueDirectionsBtnURL,
  venue_section1Title,
  venue_section1Description1,
  venue_section1Description2,
  venue_section1LinkText,
  venue_section1LinkURL,
  venue_section2Title,
  venue_section2Description1,
  venue_section2LinkText,
  venue_section2LinkURL,
  venue_section3Title,
  venue_section3Description1,
  venue_section3Description2,
  venue_section3Description3,
  venue_section3LinkText,
  venue_section3LinkURL,
  venue_section4Title,
  venue_section4Description1,
  venue_section4Description2,
  section4LinkURL,
  section4LinkText,
}) => {
  return (
    <>
      {!venue_mainText && (
        <section className="silverBG gradient flex justify-center ">
          <div className="xl:w-[85rem] w-[100%] pb-[1.875rem]">
            <div
              className="
        flex
        justify-center
        flex-col
        items-center
        text-center
        md:mx-6
        xl:mt-[7.5rem]
        md:mt-[4rem]
        mt-[3.125rem]
        overflow-hidden
        "
            >
              <div
                className="    
    w-[17.5rem]
    h-[15.125rem]
    md:w-[45.25rem]
    lg:w-[934px]
    lg:h-[18.75rem]
    xl:h-[27.625rem]
    xl:max-w-[85rem]
    xl:w-[100%]
    xl:h-[27.625rem]
    xl:w-[85rem]
    mb-[3.125rem]
    "
              >
                <img
                  id=""
                  src={venue_photoUrl}
                  alt=""
                  width=""
                  className="
      object-cover
      w-full
      h-full
      "
                />
              </div>
            </div>
          </div>
        </section>
      )}
      {venue_mainText && (
        <section className="silverBG gradient flex justify-center ">
          <div className="xl:w-[85rem] w-[100%] pb-[1.875rem]">
            <div
              className="
            flex
            justify-center
            flex-col
            items-center
            text-center
            md:mx-6
            xl:mt-[7.5rem]
            md:mt-[4rem]
            mt-[3.125rem]
            overflow-hidden
            "
            >
              <div
                className="    
              w-[17.5rem]
              h-[15.125rem]
              md:w-[45.25rem]
              lg:w-[934px]
              lg:h-[18.75rem]
              xl:h-[27.625rem]
              xl:max-w-[85rem]
              xl:w-[100%]
              xl:h-[27.625rem]
              xl:w-[85rem]
              mb-[3.125rem]
              "
              >
                <img
                  id=""
                  src={venue_photoUrl}
                  alt=""
                  width=""
                  className="
                object-cover
                w-full
                h-full
                "
                />
              </div>

              <div
                className="
              xl:text-[2.8563rem]
              xl:text-[2.8563rem]
              md:text-[2.1969rem]
              text-[1.7281rem]
              xl:mb-[30px]
              md:mb-[1.25rem]
              mb-[1.25rem]
              "
              >
                {venue_mainText}
              </div>
              <Button
                className="
              xl:mb-[3.75rem]
              md:mb-[3.75rem]
              mb-[2.5rem]
              sap-btn
              border-solid
              "
                onClick={() =>
                  window.open(venue_venueDirectionsBtnURL, "_blank")
                }
              >
                {venue_venueDirectionsBtn}
              </Button>
            </div>

            <div
              className="
            sap__lcwrapper
            "
            >
              <div className="sap__lc">
                <div className="sap__lctitle">{venue_section1Title}</div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: venue_section1Description1,
                    }}
                  ></p>
                  <p>{venue_section1Description2}</p>
                  <div className="sap__lclink">
                    <a
                      href={venue_section1LinkURL}
                      className="sap__link sap__lclink"
                    >
                      {venue_section1LinkText}
                    </a>
                  </div>
                </div>
              </div>

              <div className="sap__lc">
                <div className="sap__lctitle">{venue_section2Title}</div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: venue_section2Description1,
                    }}
                  ></p>
                  <div className="sap__lclink">
                    <a
                      href={venue_section2LinkURL}
                      className="sap__link sap__lclink"
                    >
                      {venue_section2LinkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="sap__lcwrapper xl:mb-[3.125rem]">
              <div className="sap__lc">
                <div className="sap__lctitle">{venue_section3Title}</div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: venue_section3Description1,
                    }}
                  ></p>
                  <p>{venue_section3Description2}</p>
                  <p>{venue_section3Description3}</p>
                  <div className="sap__lclink">
                    <a
                      target="_blank"
                      href={venue_section3LinkURL}
                      className="sap__link  "
                    >
                      {venue_section3LinkText}
                    </a>
                  </div>
                </div>
              </div>

              <div className="sap__lc">
                <div className="sap__lctitle">{venue_section4Title}</div>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: venue_section4Description1,
                    }}
                  ></p>
                  <p>{venue_section4Description2}</p>
                  <div className="sap__lclink">
                    <a href={section4LinkURL} className="sap__link sap__lclink">
                      {section4LinkText}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default largeDataCard;
