import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import HeroImg from "../app/component/SimpleHeroBanner";
import RefreshingWalk from "./components/RefreshingWalk";
import ShowFloor from "./components/ShowFloor";
import ThingsToDo from "./components/ThingsToDo";
import ScrollToTopSection from "./components/scrollToTopSection";
import "./index.css";

const EventExperiencePage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { eventExperienceCopy } = useSelector((state) => state.siteCopy);

  useMount(async () => {
    await dispatch(siteCopyStore.eventExperience);
  });

  return (
    <div id="ExperiencePage" className="flex flex-col">
      <HeroImg
        siteCopy={
          eventExperienceCopy.hero &&
          eventExperienceCopy.hero.mainText[language]
        }
      />

      <RefreshingWalk
        WalkPark_Header={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.mainText[language]
        }
        WalkPark_TopParagraph={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.mainDescription[language]
        }
        WalkPark_ListItem_1={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.descriptionItem1[language]
        }
        WalkPark_ListItem_2={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.descriptionItem2[language]
        }
        WalkPark_ListItem_3={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.descriptionItem3[language]
        }
        WalkPark_ListItem_4={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.descriptionItem4[language]
        }
        WalkPark_BottomParagraph={
          eventExperienceCopy.walkInThePark &&
          eventExperienceCopy.walkInThePark.endingDescription[language]
        }
      ></RefreshingWalk>
      <ThingsToDo
        mainText={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.mainText[language]
        }
        subText={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.subText[language]
        }
        card1Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card1Title[language]
        }
        card1description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card1description1[language]
        }
        card2Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Title[language]
        }
        card2Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description1[language]
        }
        card2Description2Item1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item1[language]
        }
        card2Description2Item2={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item2[language]
        }
        card2Description2Item3={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item3[language]
        }
        card2Description2Item4={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item4[language]
        }
        card2Description2Item5={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item5[language]
        }
        card2Description2Item6={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card2Description2Item6[language]
        }
        card3Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card3Title[language]
        }
        card3Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card3Description1[language]
        }
        card4Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card4Title[language]
        }
        card4Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card4Description1[language]
        }
        card5Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card5Title[language]
        }
        card5Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card5Description1[language]
        }
        card6Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card6Title[language]
        }
        card6Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card6Description1[language]
        }
        card6Description2={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card6Description2[language]
        }
        card7Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card7Title[language]
        }
        card7Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card7Description1[language]
        }
        card8Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card8Title[language]
        }
        card8Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card8Description1[language]
        }
        card9Title={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card9Title[language]
        }
        card9Description1={
          eventExperienceCopy.thingsToDo &&
          eventExperienceCopy.thingsToDo.card9Description1[language]
        }
      ></ThingsToDo>
      {eventExperienceCopy.showFloor &&
        eventExperienceCopy.showFloor.mainText[language] && (
          <ShowFloor
            ShowFloor_Header={
              eventExperienceCopy.showFloor &&
              eventExperienceCopy.showFloor.mainText[language]
            }
            ShowFloor_Paragraph={
              eventExperienceCopy.showFloor &&
              eventExperienceCopy.showFloor.description[language]
            }
            ShowFloor_ButtonText={
              eventExperienceCopy.showFloor &&
              eventExperienceCopy.showFloor.downloadFloorplanBtn[language]
            }
            ShowFloor_ButtonURL={
              eventExperienceCopy.showFloor &&
              eventExperienceCopy.showFloor.downloadFloorplanBtnURL[language]
            }
          ></ShowFloor>
        )}
      <ScrollToTopSection />
    </div>
  );
};

export default EventExperiencePage;
