import React from "react";
import { Carousel } from "../../eclipse-ux/index";
import OrangeBackground from "../assets/orange.svg";
import PropTypes from "prop-types";

const Keynote = ({
  Keynote_k1_Header,
  Keynote_k1_Description,
  Keynote_k1_Description2,
  Keynote_k1_Date,
  Keynote_k1_Url,
  Keynote_k2_Header,
  Keynote_k2_Description,
  Keynote_k2_Description2,
  Keynote_k2_Date,
  Keynote_k2_Url,
  Keynote_k3_Header,
  Keynote_k3_Description,
  Keynote_k3_Description2,
  Keynote_k3_Date,
  Keynote_k3_Url,
  Keynote_k4_Header,
  Keynote_k4_Description,
  Keynote_k4_Description2,
  Keynote_k4_Date,
  Keynote_k4_Url,
  Keynote_k5_Header,
  Keynote_k5_Description,
  Keynote_k5_Description2,
  Keynote_k5_Date,
  Keynote_k5_Url,
  Keynote_k6_Header,
  Keynote_k6_Description,
  Keynote_k6_Description2,
  Keynote_k6_Date,
  Keynote_k6_Url,
  Keynote_k7_Header,
  Keynote_k7_Description,
  Keynote_k7_Description2,
  Keynote_k7_Date,
  Keynote_k7_Url,
  Keynote_k8_Header,
  Keynote_k8_Description,
  Keynote_k8_Description2,
  Keynote_k8_Date,
  Keynote_k8_Url,
  Keynote_k9_Header,
  Keynote_k9_Description,
  Keynote_k9_Description2,
  Keynote_k9_Date,
  Keynote_k9_Url,
  Keynote_k10_Header,
  Keynote_k10_Description,
  Keynote_k10_Description2,
  Keynote_k10_Date,
  Keynote_k10_Url,
}) => {
  const arr = [];

  if (Keynote_k1_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k1_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k1_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k1_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
        dangerouslySetInnerHTML={{ __html: Keynote_k1_Description2}}
          >
            {/* {Keynote_k1_Description2} */}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k1_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k2_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k2_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k2_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k2_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k2_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k2_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k3_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k3_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k3_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k3_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k3_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k3_Date}
          </h3>
        </div>
      </div>
    );
  }

  

  if (Keynote_k5_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k5_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k5_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k5_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k5_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k5_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k6_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k6_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k6_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k6_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k6_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k6_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k7_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k7_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k7_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k7_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k7_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k7_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k8_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k8_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k8_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k8_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k8_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k8_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k9_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k9_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k9_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k9_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k9_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k9_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k10_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k10_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem]"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k10_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k10_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k10_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k10_Date}
          </h3>
        </div>
      </div>
    );
  }

  if (Keynote_k4_Header) {
    arr.push(
      <div
        style={{ backgroundImage: `url(${Keynote_k4_Url})` }}
        className="w-full h-full bg-no-repeat bg-cover bg-center"
      >
        <div
          className="z-10 
      mr-[5.9375rem] md:mr-[18.875rem] lg:mr-[27.8125rem]
      pl-[0.625rem] md:pl-[2.625rem] lg:pl-[3.875rem] 
      pt-[0.625rem] md:pt-[2.875rem] lg:pt-[4.25rem] text-white"
        >
          <h1
            className="font-normal mt-[0rem] 
        text-[1.189rem] md:text-[1.69rem] lg:text-[3rem]
        leading-[1.556rem] md:leading-[2.197rem] lg:leading-[3.625rem]"
          >
            {Keynote_k4_Header}
          </h1>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k4_Description}
          </h3>
          <h3
            className="font-normal m-0 text-base md:text-[1.063rem] lg:text-[1.69rem]
        leading-[1.5rem] md:leading-[1.625rem] lg:leading-[2.1875rem] md:w-full w-32"
          >
            {Keynote_k4_Description2}
          </h3>
          <h3
            className="font-normal 
         text-sm lg:text-base
        leading-[1.5rem] lg:leading-[1.625rem]"
          >
            {Keynote_k4_Date}
          </h3>
        </div>
      </div>
    );
  }

  const unknownSpeakerArr = [
    <div className="w-full h-full bg-no-repeat bg-cover" style={{ backgroundImage: `url(${OrangeBackground})` }}>
      <div className="flex justify-center h-full px-[33px] pt-[91px] pb-[95px] md:px-[110px] md:pt-[118px] md:pb-[124px] lg:px-[154px] lg:pt-[212px] lg:pb-[261px]">
        <p className="flex items-center justify-center text-center text-[19px] leading-[25px] md:text-[27px] md:leading-[35px] lg:text-[35px] lg:leading-[42px]">
          {Keynote_k1_Header}
        </p>
      </div>
    </div>
  ];

  return (
    <div className="w-full flex flex-col items-center bg-gradient-to-b from-[rgba(232,231,231,1)] via-[rgba(232,231,231,0)] to-[rgba(232,231,231,1)]">
      <div className="w-full px-5 max-w-[85rem] mx-auto">
        <div className="p-0 my-[3.125rem] lg:my-[7.5rem] bg-center bg-cover mx-0 md:mx-[2rem] lg:mx-[12.1875rem]">
          <Carousel
            options={ {
              classes: {
                pagination: `${arr.length > 1 ? "splide__pagination bottom-[-1.875rem]" : "hidden"}`,
              },
              type         : 'loop',
              gap          : '1rem',
              autoplay     : true,
              pauseOnHover : false,
              resetProgress: false,
              arrows       : false,
              MediaQuery: "min",
              height: "550px",
              breakpoints: {
                1360: {
                  height: "550px",
                },
                768: {
                  height: "373px",
                },
                500: {
                  height: "280px",
                },
              },
            } }
            slides={Keynote_k1_Description ? arr : unknownSpeakerArr}
          ></Carousel>
        </div>
      </div>
    </div>
  );
};

Keynote.propTypes = {
  Keynote_k1_Header: PropTypes.string,
  Keynote_k1_Description: PropTypes.string,
  Keynote_k1_Date: PropTypes.string,
  Keynote_k2_Header: PropTypes.string,
  Keynote_k2_Description: PropTypes.string,
  Keynote_k2_Date: PropTypes.string,
};

export default Keynote;
