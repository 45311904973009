import { combineReducers } from "redux";
import * as siteCopyStore  from "./sitecopy";
import { inviteStore } from "../../invite";

const rootReducer = combineReducers({
    siteCopy: siteCopyStore.reducer,
    inviteStore : inviteStore.reducer
});

export default rootReducer;
