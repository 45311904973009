import React from "react";
import { useSelector } from "react-redux";
import useEventData from "./useEventData";
import { NotFound, util } from "../../eclipse-ux";
import Navbar from "../component/Navbar/Navbar";
import Footer from "../component/Footer";
import Routes from "../model/routes";
import { useLocation } from "react-router-dom";
import { cache } from "../../eclipse-ux";

const withAppComposer =
  (WrappedComponent) =>
  ({ eventName, lang, route }) => {
    const { isLoading, eventNotFound, event } = useEventData(eventName, lang);

    if (eventNotFound) {
      window.location.href = "https://www.sap.com/about/events/sapphire.html";
      return <></>;
    }

    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);

    const source = searchParams.get("source");
    const medium = searchParams.get("medium");
    const content = searchParams.get("content");

    if (source && medium && content)
      cache.setItem("siteTracking", { source, medium, content });

    // const { config } = useSelector((state) => state.siteCopy);

    // if (!config.cookieName && isLoading) return <></>;

    // let props = {
    //   attendeeToken: String.empty,
    //   pk: String.empty,
    //   campaignCode: String.empty,
    // };

    // const cookie = util.getCookie(config.cookieName[lang]);

    // if (cookie === null && route !== Routes.INVITE)
    //   window.location.href = config.redirectUrl[lang];
    // else if (cookie !== null) {
    //   const cookieData = cookie.split("#|#");

    //   props = {
    //     attendeeToken: cookieData[1],
    //     pk: cookieData[2],
    //     campaignCode: cookieData[3],
    //   };
    // } else if (cookie === null && route === Routes.INVITE) {
    //   const { search } = useLocation();
    //   const searchParams = new URLSearchParams(search);

    //   props = {
    //     attendeeToken: searchParams.get("attendee_token"),
    //     pk: searchParams.get("PK"),
    //     campaignCode: searchParams.get("campaign_code"),
    //   };
    // }

    //TODO: Initialize props and pass to WrappedComponent
    const page = <WrappedComponent language={event} />;

    return (
      <div className="flex flex-col h-screen">
        {/* <Navbar {...props} /> */}
        <Navbar language={event} />
        <div className="grow">{page}</div>
        <Footer language={event} />
      </div>
    );
  };

export default withAppComposer;
