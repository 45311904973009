import axios from "axios";
import { util } from "../../eclipse-ux";

export const Actions = Object.freeze({
  SUBMIT: "invite/submit",
  LOAD: "invite/load",
});

export const reducer = (
  state = {
    attendeeEmail: "",
    attendeeToken: "",
    pk: "",
    campaignCode: "",
    locationId: "",
    authStatus: false,
    attempted: false,
  },
  action
) => {
  switch (action.type) {
    case Actions.SUBMIT:
      return {
        ...action.data,
      };
    case Actions.LOAD:
      return {
        ...action.data,
      };
    default:
      return state;
  }
};

export const load = () => (dispatch, getState) => {
  const language = getState().siteCopy.language;
  const name = getState().siteCopy.config.cookieName[language];
  const cookie = util.getCookie(name);

  if (cookie !== null) {
    const data = cookie.split("#|#");

    dispatch({
      type: Actions.load,
      data: {
        attendeeEmail: data[4],
        attendeeToken: data[1],
        pk: data[2],
        campaignCode: data[3],
        authStatus: true,
        attempted: true,
      },
    });
  }
};

export const submit =
  ({ attendeeEmail, pk, attendeeToken, campaignCode }) =>
  async (dispatch, getState) => {
    const language = getState().siteCopy.language;

    const locationId = getState().siteCopy.config.locationId[language];

    const url = getState().siteCopy.config.wegUrl[language];

    const response = await axios.post(url, {
      ATTENDEE_EMAIL: attendeeEmail,
      LOCATION_ID: locationId,
      ATTENDEE_TOKEN: attendeeToken,
    });

    //cookie params
    const name = getState().siteCopy.config.cookieName[language];
    const domain = getState().siteCopy.config.cookieDomain[language];
    const value = `#${locationId}#|#${attendeeToken}#|#${pk}#|#${campaignCode}#|#${attendeeEmail}#|#${Date.now()}#`;

    //stores the cookie for 365 days
    const d = new Date();
    d.setTime(
      d.getTime() +
        parseInt(getState().siteCopy.config.cookieDaysUntilExpire[language]) *
          24 *
          60 *
          60 *
          1000
    );
    const expires = d.toUTCString();

    if (response.data.AUTHENTICATION_STATUS) {
      if (process.env.NODE_ENV !== "production")
        window.document.cookie = `${name}=${value};expires=${expires};`;
      else
        window.document.cookie = `${name}=${value};domain=${domain};expires=${expires};`;
    }

    dispatch({
      type: Actions.SUBMIT,
      data: {
        attendeeEmail,
        attendeeToken,
        pk,
        campaignCode,
        authStatus: response.data.AUTHENTICATION_STATUS,
        attempted: true,
      },
    });
  };
