import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import { Carousel } from "../eclipse-ux";
import ReinventVideo from "./components/ReinventVideo";
import DontMissOut from "./components/DontMissOut";
import ByTheNumbers from "./components/ByTheNumbers";
import KeyNote from "./components/Keynote";
import CentralShowcase from "./components/CentralShowcase";
import PlanYourVisit from "./components/PlanYourVisit";
import ScrollToTopSection from "./components/scrollToTopSection";
import banner_background from "../app/asset/Banner-Background.svg";
import HeroImg from "./components/HeroImg";
import "./index.css";

const HomePage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { homeCopy } = useSelector((state) => state.siteCopy);

  useMount(async () => {
    await dispatch(siteCopyStore.home);
  });

  const arr = [];

  if (homeCopy.hero) {
    if (homeCopy.hero.mainText[language]) {
      arr.push(
        <div
          className="grid grid-cols-2 w-full h-[240px] md:h-[440px]"
          style={{ backgroundImage: `url(${banner_background})` }}
        >
          <div className="flex flex-col col-span-2 items-center text-center md:text-left md:col-span-1 lg:items-start justify-center ml-auto w-full max-w-[715px] py-10 md:py-0 px-12">
            <div className="md:max-w-[360px] desktop:max-w-none">
              <h1
                className="m-0 text-xl md:text-[35.15px] md:leading-[42.16px] desktop:text-[45.7px] desktop:leading-[54.8px]"
                dangerouslySetInnerHTML={{
                  __html: homeCopy.hero.mainText[language],
                }}
              ></h1>
              {homeCopy.hero.subText[language] && (
                <h3 className="m-0 pt-2">{homeCopy.hero.subText[language]}</h3>
              )}
              {homeCopy.hero.btnText[language] && (
                <div className="m-0 pt-8">
                  <a
                    href={homeCopy.hero.btnUrl[language]}
                    className="heroBanner__button"
                  >
                    {homeCopy.hero.btnText[language]}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div
            className="hidden md:block justify-self-end md:w-[400px] lg:w-[635px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${homeCopy.hero.imgUrl[language]})`,
            }}
          ></div>
        </div>
      );
    }

    if (homeCopy.hero.main2Text[language]) {
      arr.push(
        <div
          className="grid grid-cols-2 w-full h-[240px] md:h-[440px]"
          style={{ backgroundImage: `url(${banner_background})` }}
        >
          <div className="flex flex-col col-span-2 items-center text-center md:text-left md:col-span-1 lg:items-start justify-center ml-auto w-full max-w-[715px] py-10 md:py-0 px-12">
            <div className="md:max-w-[360px] desktop:max-w-none">
              <h1
                className="m-0 text-xl md:text-[35.15px] md:leading-[42.16px] desktop:text-[45.7px] desktop:leading-[54.8px]"
                dangerouslySetInnerHTML={{
                  __html: homeCopy.hero.main2Text[language],
                }}
              ></h1>
              {homeCopy.hero.sub2Text[language] && (
                <h3 className="m-0 pt-2">{homeCopy.hero.sub2Text[language]}</h3>
              )}
              {homeCopy.hero.btn2Text[language] && (
                <div className="m-0 pt-8">
                  <a
                    href={homeCopy.hero.btn2Url[language]}
                    className="heroBanner__button"
                  >
                    {homeCopy.hero.btn2Text[language]}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div
            className="hidden md:block justify-self-end md:w-[400px] lg:w-[635px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${homeCopy.hero.img2Url[language]})`,
            }}
          ></div>
        </div>
      );
    }

    if (homeCopy.hero.main3Text[language]) {
      arr.push(
        <div
          className="grid grid-cols-2 w-full h-[240px] md:h-[440px]"
          style={{ backgroundImage: `url(${banner_background})` }}
        >
          <div className="flex flex-col col-span-2 items-center text-center md:text-left md:col-span-1 lg:items-start justify-center ml-auto w-full max-w-[715px] py-10 md:py-0 px-12">
            <div className="md:max-w-[360px] desktop:max-w-none">
              <h1
                className="m-0 text-xl md:text-[35.15px] md:leading-[42.16px] desktop:text-[45.7px] desktop:leading-[54.8px]"
                dangerouslySetInnerHTML={{
                  __html: homeCopy.hero.main3Text[language],
                }}
              ></h1>
              {homeCopy.hero.sub3Text[language] && (
                <h3 className="m-0 pt-2">{homeCopy.hero.sub3Text[language]}</h3>
              )}
              {homeCopy.hero.btn3Text[language] && (
                <div className="m-0 pt-8">
                  <a
                    href={homeCopy.hero.btn3Url[language]}
                    className="heroBanner__button"
                  >
                    {homeCopy.hero.btn3Text[language]}
                  </a>
                </div>
              )}
            </div>
          </div>
          <div
            className="hidden md:block justify-self-end md:w-[400px] lg:w-[635px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${homeCopy.hero.img3Url[language]})`,
            }}
          ></div>
        </div>
      );
    }
  }

  return (
    <div id="homePage" className="flex flex-col">
      {arr.length > 1 && (
        <Carousel
          options={{
            classes: {
              pagination: "splide__pagination bottom-[-2rem]",
              arrow: "splide__arrow bg-remove hidden lg:inline-block",
            },
            type: "loop",
            autoplay: true,
            pauseOnHover: false,
            resetProgress: false,
            arrows: true,
          }}
          slides={arr}
        ></Carousel>
      )}
      {arr.length === 1 && (
        <HeroImg
          HeroImg_Header={homeCopy.hero && homeCopy.hero.mainText[language]}
          HeroImg_Date={homeCopy.hero && homeCopy.hero.subText[language]}
        />
      )}
      {homeCopy.reinvent && homeCopy.reinvent.videoURL[language] && (
        <ReinventVideo
          ReinventVideo_Header={homeCopy.reinvent.mainText[language]}
          ReinventVideo_Paragraph={homeCopy.reinvent.subText[language]}
          ReinventVideo_VideoSRC={homeCopy.reinvent.videoURL[language]}
          ReinventVideo_VideoPoster={homeCopy.reinvent.posterURL[language]}
        ></ReinventVideo>
      )}
      {false && (
        <DontMissOut
          DontMissOut_Header={
            homeCopy.dontMissOut && homeCopy.dontMissOut.mainText[language]
          }
          DontMissOut_Paragraph1={
            homeCopy.dontMissOut && homeCopy.dontMissOut.subText[language]
          }
          DontMissOut_Paragraph2={
            homeCopy.dontMissOut && homeCopy.dontMissOut.overviewText[language]
          }
          DontMissOut_Paragraph3={
            ""
            // homeCopy.dontMissOut && homeCopy.dontMissOut.overviewText2[language]
          }
          DontMissOut_Image1_Header={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason1MainText[language]
          }
          DontMissOut_Image1_Paragraph={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason1Description[language]
          }
          DontMissOut_Image2_Header={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason2MainText[language]
          }
          DontMissOut_Image2_Paragraph={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason2Description[language]
          }
          DontMissOut_Image3_Header={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason3MainText[language]
          }
          DontMissOut_Image3_Paragraph={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reason3Description[language]
          }
          DontMissOut_Bottom_Paragraph1={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reasonCard1Text[language]
          }
          DontMissOut_Bottom_Paragraph2={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reasonCard2Text[language]
          }
          DontMissOut_Bottom_Paragraph3={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reasonCard3Text[language]
          }
          DontMissOut_Bottom_Header={
            homeCopy.dontMissOut &&
            homeCopy.dontMissOut.reasonCardsTitle[language]
          }
        ></DontMissOut>
      )}
      <ByTheNumbers
        ByTheNumbers_Header={
          homeCopy.byTheNumbers && homeCopy.byTheNumbers.mainText[language]
        }
        ByTheNumbers_Breakout_Numbers={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.breakoutSessionSpacesNumber[language]
        }
        ByTheNumbers_Breakout_Text={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.breakoutSessionSpacesText[language]
        }
        ByTheNumbers_Customers_Numbers={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.customersNumber[language]
        }
        ByTheNumbers_Customers_Text={
          homeCopy.byTheNumbers && homeCopy.byTheNumbers.customersText[language]
        }
        ByTheNumbers_Sessions_Numbers={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.sessionsNumber[language]
        }
        ByTheNumbers_Sessions_Text={
          homeCopy.byTheNumbers && homeCopy.byTheNumbers.sessionsText[language]
        }
        ByTheNumbers_Solution_Numbers={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.solutionCenterNumber[language]
        }
        ByTheNumbers_Solution_Text={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.solutionCenterText[language]
        }
        ByTheNumbers_Vision_Numbers={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.visionTheaterSessionNumber[language]
        }
        ByTheNumbers_Vision_Text={
          homeCopy.byTheNumbers &&
          homeCopy.byTheNumbers.visionTheaterSessionText[language]
        }
      ></ByTheNumbers>
      <KeyNote
        Keynote_k1_Flag={
          homeCopy.keyNote && homeCopy.keyNote.slide1MainText[language]
        }
        Keynote_k1_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide1MainText[language]
        }
        Keynote_k1_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide1Sub2Text[language]
        }
        Keynote_k1_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide1Sub3Text[language]
        }
        Keynote_k1_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide1SubText[language]
        }
        Keynote_k1_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide1ImgUrl[language]
        }
        Keynote_k2_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide2MainText[language]
        }
        Keynote_k2_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide2Sub2Text[language]
        }
        Keynote_k2_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide2Sub3Text[language]
        }
        Keynote_k2_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide2SubText[language]
        }
        Keynote_k2_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide2ImgUrl[language]
        }
        Keynote_k3_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide3MainText[language]
        }
        Keynote_k3_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide3Sub2Text[language]
        }
        Keynote_k3_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide3Sub3Text[language]
        }
        Keynote_k3_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide3SubText[language]
        }
        Keynote_k3_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide3ImgUrl[language]
        }
        Keynote_k4_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide4MainText[language]
        }
        Keynote_k4_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide4Sub2Text[language]
        }
        Keynote_k4_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide4Sub3Text[language]
        }
        Keynote_k4_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide4SubText[language]
        }
        Keynote_k4_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide4ImgUrl[language]
        }
        Keynote_k5_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide5MainText[language]
        }
        Keynote_k5_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide5Sub2Text[language]
        }
        Keynote_k5_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide5Sub3Text[language]
        }
        Keynote_k5_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide5SubText[language]
        }
        Keynote_k5_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide5ImgUrl[language]
        }
        Keynote_k6_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide6MainText[language]
        }
        Keynote_k6_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide6Sub2Text[language]
        }
        Keynote_k6_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide6Sub3Text[language]
        }
        Keynote_k6_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide6SubText[language]
        }
        Keynote_k6_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide6ImgUrl[language]
        }
        Keynote_k7_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide7MainText[language]
        }
        Keynote_k7_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide7Sub2Text[language]
        }
        Keynote_k7_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide7Sub3Text[language]
        }
        Keynote_k7_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide7SubText[language]
        }
        Keynote_k7_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide7ImgUrl[language]
        }
        Keynote_k8_Header={
          homeCopy.keyNote && homeCopy.keyNote.slide8MainText[language]
        }
        Keynote_k8_Description={
          homeCopy.keyNote && homeCopy.keyNote.slide8Sub2Text[language]
        }
        Keynote_k8_Description2={
          homeCopy.keyNote && homeCopy.keyNote.slide8Sub3Text[language]
        }
        Keynote_k8_Date={
          homeCopy.keyNote && homeCopy.keyNote.slide8SubText[language]
        }
        Keynote_k8_Url={
          homeCopy.keyNote && homeCopy.keyNote.slide8ImgUrl[language]
        }
      ></KeyNote>
      <CentralShowcase
        CentralShowcase_Header={
          homeCopy.centralShowcase &&
          homeCopy.centralShowcase.cardMainText[language]
        }
        CentralShowcase_Paragraph={
          homeCopy.centralShowcase &&
          homeCopy.centralShowcase.cardSubText[language]
        }
      ></CentralShowcase>
      {homeCopy.planYourVisit && homeCopy.planYourVisit.mainText[language] && (
        <PlanYourVisit
          PlanYourVisit_Header={
            homeCopy.planYourVisit && homeCopy.planYourVisit.mainText[language]
          }
          PlanYourVisit_CardHeader_1={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card1MainText[language]
          }
          PlanYourVisit_CardParagraph_1={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card1SubText[language]
          }
          PlanYourVisit_CardButtonURL_1={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card1LearnMoreLinkURL[language]
          }
          PlanYourVisit_CardButtonText_1={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card1LearnMoreLink[language]
          }
          PlanYourVisit_CardHeader_2={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card2MainText[language]
          }
          PlanYourVisit_CardParagraph_2={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card2SubText[language]
          }
          PlanYourVisit_CardButtonURL_2={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card2LearnMoreLinkURL[language]
          }
          PlanYourVisit_CardButtonText_2={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card2LearnMoreLink[language]
          }
          PlanYourVisit_CardHeader_3={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card3MainText[language]
          }
          PlanYourVisit_CardParagraph_3={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card3SubText[language]
          }
          PlanYourVisit_CardButtonURL_3={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card3LearnMoreLinkURL[language]
          }
          PlanYourVisit_CardButtonText_3={
            homeCopy.planYourVisit &&
            homeCopy.planYourVisit.card2LearnMoreLink[language]
          }
        ></PlanYourVisit>
      )}
      <ScrollToTopSection></ScrollToTopSection>
    </div>
  );
};

export default HomePage;
