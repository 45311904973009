import React from "react";
import Button from "@mui/material/Button";
import "./dataCard.css";
import smallStock from "../../assets/285503_concur.hotel.2018_00680_retouched.jpg";
const dataCard = ({
  hotelBooking_mainTitle,
  hotelBooking_description,
  hotelBooking_mapBtn,
  hotelBooking_mapBtnURL,
  hotelBooking_registrationBtn,
  hotelBooking_registrationBtnURL,
}) => {
  return (
    <>
      <section className="sap__card gradientB">
        <div className="sap__cardBody ">
          <div className="sap__cardImageWrapper">
            <img src={smallStock} alt="" className="sap__cardImage" />
          </div>
          <div className="sap__cardWrapper">
            <div className="sap__cardTitle">{hotelBooking_mainTitle}</div>
            <div className="sap__cardCopy">
              <p
                dangerouslySetInnerHTML={{ __html: hotelBooking_description }}
              ></p>
            </div>
            {hotelBooking_mapBtn && (
              <div className="sap__cardButtonWRapper ">
                <a
                  href={hotelBooking_mapBtnURL}
                  className="sap-btn sap__cardBtn hidden md:flex no-underline items-center"
                >
                  {hotelBooking_mapBtn}
                </a>
                <div className="md:hidden block">
                  <a href={hotelBooking_mapBtnURL} className="sap__link">
                    {hotelBooking_mapBtn}
                  </a>
                </div>
              </div>
            )}
            <div className="sap__cardButtonWRapper ">
              {hotelBooking_registrationBtn && (
                <Button
                  onClick={() =>
                    (window.location.href = hotelBooking_registrationBtnURL)
                  }
                  className="sap-btn sap__cardBtn hidden md:flex"
                >
                  {hotelBooking_registrationBtn}
                </Button>
              )}

              <div className="md:hidden block">
                <a href={hotelBooking_registrationBtnURL} className="sap__link">
                  {hotelBooking_registrationBtn}
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default dataCard;
