import React from "react";
import PropTypes from 'prop-types';

const HeroImg = ({HeroImg_Header, HeroImg_Date}) => {
  return (
    <div
      className="hero__img flex justify-center items-center w-full h-[12.5rem] md:h-[25.25rem]"
    >
      <div className="custom-breakpoint-container w-full flex flex-col py-16 justify-start">
        <h1 dangerouslySetInnerHTML={{__html: HeroImg_Header}} className="m-0 max-w-none md:max-w-[26.125rem] lg:max-w-[36.6875rem]"></h1>
        <h3 className="m-0 pt-2">{HeroImg_Date}</h3>
      </div>
    </div>
  );
};

HeroImg.propTypes ={
  HeroImg_Header: PropTypes.string,
  HeroImg_Date: PropTypes.string
}

export default HeroImg;
