import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import banner_background from "../app/asset/Banner-Background.svg";

const MobileAppLandingPage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { mobileAppLandingCopy } = useSelector((state) => state.siteCopy);

  useMount(async () => {
    await dispatch(siteCopyStore.mobileAppLanding);
  });

  const mobileFeatureColumn = (header, subHeader, imgSrc) => {
    return (
      <div className="flex flex-col items-center justify-center desktop:max-w-[360px] max-w-[642px]">
        <img className="max-w-[225px] sm:max-w-[360px]" src={imgSrc} />
        <h1 className="text-center font-normal text-xl">{header}</h1>
        <p
          className="text-center font-normal text-base leading-6 text-sap-black-545454"
          dangerouslySetInnerHTML={{ __html: subHeader }}
        ></p>
      </div>
    );
  };

  const sectionHeader = (header, subHeader) => {
    return (
      <div>
        <h1 className="font-normal text-[1.688rem] leading-[2.197rem]">
          {header}
        </h1>
        {subHeader && (
          <p
            className="text-sap-black-545454 text-base font-normal pb-10"
            dangerouslySetInnerHTML={{ __html: subHeader }}
          ></p>
        )}
      </div>
    );
  };

  const bulletSections = (header, bulletPoints, imgLink, imgUrl) => {
    return (
      <div >
        {imgUrl && (
          <a target="_blank" href={imgLink}>
            <img className="h-[40px]" src={imgUrl} />
          </a>
        )}
        <h1 className="font-normal text-base">{header}</h1>
        <ul className="pb-7">
          {bulletPoints.map((m) => {
            return (
              <li className="font-normal text-base text-sap-black-545454">
                {m}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      {mobileAppLandingCopy.hero && (
        <div
          className="grid grid-cols-2 w-full h-[240px] md:h-[300px]"
          style={{ backgroundImage: `url(${banner_background})` }}
        >
          <div className="flex flex-col col-span-2 items-center text-center md:text-left md:col-span-1 lg:items-start justify-center ml-auto w-full max-w-[715px] py-10 md:py-0 sm:pl-12 sm:px-0 px-12">
            <div className="max-w-[330px] md:max-w-[360px] desktop:max-w-none">
              <h1 className="font-normal m-0 text-[1.44rem] leading-[1.869rem] md:text-[1.688rem] md:leading-[2.197rem] desktop:text-[2.813rem] desktop:leading-[3.428rem]">
                {mobileAppLandingCopy.hero.mainText[language]}
              </h1>
              {mobileAppLandingCopy.hero.subText[language] && (
                <h3
                  className="font-normal m-0 pt-2 desktop:text-[1.69rem] md:text-[1.3rem] text-[1.2rem]"
                  dangerouslySetInnerHTML={{
                    __html: mobileAppLandingCopy.hero.subText[language],
                  }}
                ></h3>
              )}
            </div>
          </div>
          <div
            className="hidden desktop:block justify-self-end desktop:w-[635px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${mobileAppLandingCopy.hero.imgUrl[language]})`,
            }}
          ></div>
          <div
            className="desktop:hidden md:block hidden  justify-self-end w-[320px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${mobileAppLandingCopy.hero.imgUrlTablet[language]})`,
            }}
          ></div>
        </div>
      )}

      {mobileAppLandingCopy.download && (
        <div className="silverBG">
          <div className="desktop:mt-[8.438rem] md:mt-16 mt-12 custom-breakpoint-container flex flex-col items-center">
            <div className="grid grid-cols-1 sm:gap-5 sm:grid-cols-2 w-full">
              <div className=" desktop:pb-[17.563rem] tablet:pb-[4.313rem] pb-8">
                <h1 className="font-normal text-[1.688rem] leading-[2.197rem]">
                  {mobileAppLandingCopy.download.mainText[language]}
                </h1>
                <p className="desktop:mt-6 mt-5 text-base">
                  {mobileAppLandingCopy.download.subText[language]}
                </p>
                <p className="desktop:mt-2 desktop:mb-7 mt-3 mb-7 text-base">
                  {mobileAppLandingCopy.download.subText2[language]}
                </p>
                <div className="flex flex-row items-center justify-center sm:items-start sm:justify-start space-x-[0.813rem]">
                  <a
                    target="_blank"
                    href={mobileAppLandingCopy.download.appleLink[language]}
                  >
                    <img
                      className="h-[40px]"
                      src={mobileAppLandingCopy.download.appleIcon[language]}
                    />
                  </a>
                  <a
                    target="_blank"
                    href={mobileAppLandingCopy.download.googleLink[language]}
                  >
                    <img
                      className="h-[40px]"
                      src={mobileAppLandingCopy.download.googleIcon[language]}
                    />
                  </a>
                </div>
              </div>
              <div className="flex items-center sm:items-end justify-self-center">
                <img
                  className="hidden desktop:block h-[560px]"
                  src={mobileAppLandingCopy.download.imgUrl[language]}
                />
                <img
                  className="desktop:hidden h-[440px]"
                  src={mobileAppLandingCopy.download.imgUrlTablet[language]}
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {mobileAppLandingCopy.features && (
        <div className="bg-sap-black-545454 px-5">
          <div className="desktop:my-[8.438rem] md:my-12 my-12 desktop:px-14 desktop:pb-20 pb-16 pt-14 custom-breakpoint-container flex flex-col items-center justify-center bg-white">
            <h1 className="text-center font-normal text-4xl">
              {mobileAppLandingCopy.features.mainText[language]}
            </h1>
            <div className="grid grid-cols-1 desktop:grid-cols-3 w-full mt-9 desktop:gap-20 gap-11 justify-items-center">
              {mobileFeatureColumn(
                mobileAppLandingCopy.features.column1MainText[language],
                mobileAppLandingCopy.features.column1SubText[language],
                mobileAppLandingCopy.features.column1ImgUrl[language]
              )}
              {mobileFeatureColumn(
                mobileAppLandingCopy.features.column2MainText[language],
                mobileAppLandingCopy.features.column2SubText[language],
                mobileAppLandingCopy.features.column2ImgUrl[language]
              )}
              {mobileFeatureColumn(
                mobileAppLandingCopy.features.column3MainText[language],
                mobileAppLandingCopy.features.column3SubText[language],
                mobileAppLandingCopy.features.column3ImgUrl[language]
              )}
            </div>
          </div>
        </div>
      )}

      {mobileAppLandingCopy.details && (
        <div className="bg-white">
          <div className="desktop:my-[8.438rem] md:my-16 my-12 custom-breakpoint-container flex flex-col items-center">
            <div className="grid grid-cols-1 desktop:gap-5 desktop:grid-cols-2 w-full">
              <div>
                {sectionHeader(
                  mobileAppLandingCopy.details.section1MainText[language]
                )}
                {bulletSections(
                  mobileAppLandingCopy.details.section1Item1MainText[language],
                  [
                    mobileAppLandingCopy.details.section1Item1Bullet1[language],
                    mobileAppLandingCopy.details.section1Item1Bullet2[language],
                  ]
                )}
                {bulletSections(
                  mobileAppLandingCopy.details.section1Item2MainText[language],
                  [
                    mobileAppLandingCopy.details.section1Item2Bullet1[language],
                    mobileAppLandingCopy.details.section1Item2Bullet2[language],
                    mobileAppLandingCopy.details.section1Item2Bullet3[language],
                    mobileAppLandingCopy.details.section1Item2Bullet4[language],
                  ],
                  mobileAppLandingCopy.details.section1Item2ImgUrlLink[
                    language
                  ],
                  mobileAppLandingCopy.details.section1Item2ImgUrl[language]
                )}
                {bulletSections(
                  mobileAppLandingCopy.details.section1Item3MainText[language],
                  [
                    mobileAppLandingCopy.details.section1Item3Bullet1[language],
                    mobileAppLandingCopy.details.section1Item3Bullet2[language],
                    mobileAppLandingCopy.details.section1Item3Bullet3[language],
                  ],
                  mobileAppLandingCopy.details.section1Item3ImgUrlLink[
                    language
                  ],
                  mobileAppLandingCopy.details.section1Item3ImgUrl[language]
                )}
                {sectionHeader(
                  mobileAppLandingCopy.details.section2MainText[language],
                  mobileAppLandingCopy.details.section2SubText[language]
                )}
              </div>
              <div>
                {sectionHeader(
                  mobileAppLandingCopy.details.section3MainText[language],
                  mobileAppLandingCopy.details.section3SubText[language]
                )}
                {sectionHeader(
                  mobileAppLandingCopy.details.section4MainText[language],
                  mobileAppLandingCopy.details.section4SubText[language]
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileAppLandingPage;
