import React from "react";
import OrangeBackground from "../assets/background_bynumbers.png";
import propTypes from "prop-types";

const ByTheNumbers = ({ 
  ByTheNumbers_Header,
  ByTheNumbers_Breakout_Numbers,
  ByTheNumbers_Breakout_Text,
  ByTheNumbers_Customers_Numbers,
  ByTheNumbers_Customers_Text,
  ByTheNumbers_Sessions_Numbers,
  ByTheNumbers_Sessions_Text,
  ByTheNumbers_Solution_Numbers,
  ByTheNumbers_Solution_Text,
  ByTheNumbers_Vision_Numbers,
  ByTheNumbers_Vision_Text }) => {
  return (
    <div
      style={{ backgroundImage: `url(${OrangeBackground})` }}
      className="w-full flex items-center flex-col bg-cover bg-center"
    >
      <div className="custom-breakpoint-container my-[3.125rem] lg:my-[7.5rem] flex items-center flex-col">
        
        <div className="px-[2rem] w-full md:px-[0.375rem] shadow-[0px_19px_38px_0px_rgba(0,0,0,0.16)] 
                      bg-white pb-10">
        <h1 className="md:text-[2.8563rem] text-center pt-5 lg:pb-10">
          {ByTheNumbers_Header}
        </h1>
        <div className=" grid grid-rows-5  w-full px-[29px] 
                        divide-solid divide-y-2 divide-x-0 md:grid-cols-5 md:grid-rows-1
                        md:divide-y-0 md:divide-x-2 md:px-[1.25rem]">
          <div className="text-sap-gray-4B4B4B flex flex-col items-center justify-center md:justify-start  py-[1.875rem] md:py-[1.125rem] md:px-[1.125rem]">
            <p className="text-center font-black text-[2.125rem] lg:text-[3.125rem] leading-[2.125rem]">{ByTheNumbers_Vision_Numbers}</p>
            <p className="text-center text-[1rem] leading-[1.375rem]">{ByTheNumbers_Vision_Text}</p>
          </div>
          <div className="text-sap-gray-4B4B4B flex flex-col items-center justify-center md:justify-start py-[1.875rem] md:py-[1.125rem] md:px-[1.125rem]">
            <p className="text-center font-black text-[2.125rem] lg:text-[3.125rem] leading-[2.125rem]">{ByTheNumbers_Solution_Numbers}</p>
            <p className="text-center text-[1rem] leading-[1.375rem]">{ByTheNumbers_Solution_Text}</p>
          </div>
          <div className="text-sap-gray-4B4B4B flex flex-col items-center justify-center md:justify-start py-[1.875rem] md:py-[1.125rem] md:px-[1.125rem]">
            <p className="text-center font-black text-[2.125rem] lg:text-[3.125rem] leading-[2.125rem]">{ByTheNumbers_Breakout_Numbers}</p>
            <p className="text-center text-[1rem] leading-[1.375rem]">{ByTheNumbers_Breakout_Text}</p>
          </div>
          <div className="text-sap-gray-4B4B4B flex flex-col items-center justify-center md:justify-start py-[1.875rem] md:py-[1.125rem] md:px-[1.125rem]">
            <p className="text-center font-black text-[2.125rem] lg:text-[3.125rem] leading-[2.125rem]">{ByTheNumbers_Sessions_Numbers}</p>
            <p className="text-center text-[1rem] leading-[1.375rem]">{ByTheNumbers_Sessions_Text}</p>
          </div>
          <div className="text-sap-gray-4B4B4B flex flex-col items-center justify-center md:justify-start py-[1.875rem] md:py-[1.125rem] md:px-[1.125rem]">
            <p className="text-center font-black text-[2.125rem] lg:text-[3.125rem] leading-[2.125rem]">{ByTheNumbers_Customers_Numbers}</p>
            <p className="text-center text-[1rem] leading-[1.375rem]">{ByTheNumbers_Customers_Text}</p>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

ByTheNumbers.propTypes = {
  ByTheNumbers_Header: propTypes.string,
};

export default ByTheNumbers;
