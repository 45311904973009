import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material";
import "../index.css";

const BasicNavBar = ({
  logoTray,
  linkTray,
  actionTray,
  drawerWidth = 240,
  drawerTray,
  drawerMenu,
  className,
  mobileTray,
  languageSwitcher,
}) => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1245,
        xl: 1536,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }} className="topNav">
        <AppBar
          className={`__default-app-bar ${className}`}
          // stick the header
          sx={{
            position: { xs: "fixed", lg: "static" },
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
        >
          {/* sets fixed size at design hights width and then goes for mobile */}
          <Toolbar className="w-[100%] max-w-[85rem] px-[0.75rem]">
            {/* the logo */}
            <div className="desktop:hidden">{logoTray}</div>
            {/* the container for mobile view*/}
            <Box
              sx={{ display: { xs: "flex", lg: "none" } }}
              className="ml-auto items-center"
            >
              {/* the mid mobile view - registration btn & banner */}
              <Box
                sx={{
                  display: { xs: "none", sm: "flex", lg: "none" },
                }}
              >
                {drawerTray}
              </Box>
              {/* the SAP baner on the right */}
              <Box
                sx={{
                  display: { xs: "flex", sm: "none", lg: "none" },
                }}
              >
                {mobileTray}
              </Box>
              {/* the hamburger menu btn */}
              <IconButton size="large" onClick={toggleDrawer} color="inherit">
                <MenuIcon className="fill-black" />
              </IconButton>
            </Box>
            {/* the full nav menu items */}
            <Box sx={{ display: { xs: "none", lg: "flex" } }}>
              <div className="flex flex-col">
                {logoTray}
                {linkTray}
              </div>
            </Box>
            {/* the email/reg button stuff */}
            <Box
              className="ml-auto"
              sx={{ display: { xs: "none", lg: "flex" } }}
            >
              {actionTray}
            </Box>
          </Toolbar>
        </AppBar>
        {/* the actual mobile drawer */}
        <Drawer
          className="topnav__drawer"
          variant="persistent"
          open={open}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          anchor="right"
        >
          <Toolbar />
          {drawerMenu}
        </Drawer>
        <Toolbar />
      </Box>
      <div className="relative">
      {languageSwitcher}
      </div>
    </ThemeProvider>
  );
};

export default BasicNavBar;
