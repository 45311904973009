import {React, useState} from 'react';
import downArrow from '../assets/down-arrow.svg'
import upArrow from '../assets/up-arrow.svg'


const getList = (data) => {
    //console.log(data)
    if(data.startsWith('•\t') ){
        let splitText = data.split('•\t')
        
        return(
            <div className=''>
                {splitText.map((copy,index) => {
                    if(copy){
                        return <p key={index} className='p-0 flex flex-row text-[#545454] text-[1rem] leading-[1.5rem] mb-[1.25rem] addDot'>{copy}</p>
                    }
                })}
            </div>
        )
    }else{
        let initialSplit = data.split('|')
        /*
        so this used to be 
        let initialSplit = data.split(':')
        it was breaking the way we imported the site copy.
        why it used to use : I have no idea! But if it breaks something else, sorry!
        @Barron Brock and I'll help yopu fix it
        */
        let splitText = ""
        if(initialSplit[1] != undefined){
            splitText=  initialSplit[1].split('•')
        }else{
            splitText=  initialSplit[0].split('•')
        }
         //console.log(splitText)
        return(
            <div className=''>
                {/* <div className='text-[#545454] text-[1rem] leading-[1.5rem] mb-[1.25rem]'>{initialSplit[0]+':'}</div> */}
                {splitText.map((copy,index) => {
                    if(copy && index!=0){
                        return <p key={index} className='p-0 flex flex-row text-[#545454] text-[1rem] leading-[1.5rem]  mb-[1.25rem] addDot'>{copy}</p>
                    }
                })}
            </div>
        )
    }
}

const CollapsableData = ({siteCopy, language}) => {
    // const [buttonState, setButtonState] = useState("closed")
    // const [arrowType, setArrowType] = useState(downArrow)
    // const [buttonText, setButtonText] = useState("Show More")
    // const [classState, setClassState] = useState('h-[125px] overflow-hidden md:h-[164px] lg:h-[155px]')
    
    // const handleClick = () => {
    //     if(buttonState === "closed"){
    //         setArrowType(upArrow)
    //         setButtonText("Show Less")
    //         setClassState('')
    //         setButtonState("open")
    //     }else{
    //         setArrowType(downArrow)
    //         setButtonText("Show More")
    //         setClassState('h-[125px] overflow-hidden md:h-[164px] lg:h-[155px]')
    //         setButtonState("closed")
    //     }
        
    // }
    
    let copyObj = {}
    if(siteCopy){
        Object.keys(siteCopy).forEach((data) => {
            if(data.endsWith('Title')){
                copyObj["title"] = siteCopy[data][language]
            }else if(data.endsWith('MainDescription')){
                copyObj["mainDescription"] = siteCopy[data][language]
            }else if(data.endsWith('DescriptionItem1')){
                copyObj["descriptionItem1"] = siteCopy[data][language]
            }else if(data.endsWith('DescriptionItem2')){
                copyObj["descriptionItem2"] = siteCopy[data][language]
            }else if(data.endsWith('DescriptionItem3')){
                copyObj["descriptionItem3"] = siteCopy[data][language]
            }else{}
        })
         //console.log(copyObj , " copy object")

        // console.log("here: "+copyObj.descriptionItem1)
    }

    return (
        <div className=''>
            <p className='
            p-0
            text-[1.4375rem] leading-[1.875rem] 
            md:text-[1.6875rem] md:leading-[2.1875rem]
            lg:text-[2.1875rem] lg:leading-[2.625rem]
            mb-[1.25rem] '>{siteCopy && copyObj.title}</p>
            
            <div>
                <p className='p-0 text-[#545454] text-[1rem] leading-[1.5rem]  mb-[1.25rem]'>{siteCopy && copyObj.mainDescription}</p>
                {copyObj.descriptionItem1 && copyObj.descriptionItem1.includes('•') ? getList(copyObj.descriptionItem1): <p className='p-0 text-[#545454] pb-0 text-[1rem] leading-[1.5rem]  mb-[1.25rem]'>{copyObj.descriptionItem1}</p>}
                {copyObj.descriptionItem2 && copyObj.descriptionItem2.includes('•') ? getList(copyObj.descriptionItem2): <p className='p-0 text-[#545454] pb-0 text-[1rem] leading-[1.5rem]  mb-[1.25rem]'>{copyObj.descriptionItem2}</p>}
                {copyObj.descriptionItem3 && copyObj.descriptionItem3.includes('•') ? getList(copyObj.descriptionItem3): <p className='p-0 text-[#545454] pb-0 text-[1rem] leading-[1.5rem]  mb-[1.25rem]'>{copyObj.descriptionItem3}</p>}
            </div>
            
     
        </div>
    );
}

export default CollapsableData;