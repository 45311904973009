import { useState } from "react";
import { useMount } from "react-use";
import { useDispatch } from "react-redux";
import { InvalidEventError } from "../../eclipse-ux";
import { siteCopyStore } from "..";
import { inviteStore } from "../../invite";

function useEventData(eventName, lang) {
  const dispatch = useDispatch();
  const [event, setEvent] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [eventNotFound, setEventNotFound] = useState(false);

  useMount(async () => {
    try {
     const lowercaseEventName = eventName.toLowerCase();

      if (
        ![
          //"orlando",
          //"mexico",
          //"thehague",
          //"madrid",
          // "zurich",
          // "tokyo",
          // "saopaulo",
          //"munich",
        ].includes(lowercaseEventName)
      ) {
        throw new InvalidEventError("not a registered event");
      }

      if((lang === "en" || lang === "default")&& lowercaseEventName === "orlando"){
        lang = "en";
        setEvent("en");
      }

      if((lang === "es" || lang === "default") && lowercaseEventName === "mexico"){
        lang = "es";
        setEvent("es-Mexico");
      }

      if(lang === "es_MX" && lowercaseEventName === "mexico"){
        lang = "es_MX";
        setEvent("es-MX-Mexico");
      }

      if((lang === "en" || lang === "default")&& lowercaseEventName === "thehague"){
        lang = "en";
        setEvent("en-TheHague");
      }

      if((lang === "es" || lang === "default") && lowercaseEventName === "madrid"){
        lang = "es";
        setEvent("es-Madrid");
      }

      if(lang === "en" && lowercaseEventName === "madrid"){
        lang = "en";
        setEvent("en-Madrid");
      }

      if((lang === "en" || lang === "default") && lowercaseEventName === "zurich"){
        lang = "en";
        setEvent("en-Zurich");
      }

      if(lang === "de" && lowercaseEventName === "zurich"){
        lang = "de";
        setEvent("de-Zurich");
      }

      if((lang === "ja" || lang === "default") && lowercaseEventName === "tokyo"){
        lang = "ja";
        setEvent("ja-Tokyo");
      }

      if(lang === "en" && lowercaseEventName === "tokyo"){
        lang = "en";
        setEvent("en-Tokyo");
      }

      if((lang === "de" || lang === "default") && lowercaseEventName === "munich"){
        lang = "de";
        setEvent("de-Munich");
      }

      if((lang === "pt" || lang === "default") && lowercaseEventName === "saopaulo"){
        lang = "pt";
        setEvent("pt-SaoPaulo");
      }

      if (process.env.NODE_ENV == "production") {
        _trackData({
          events: {
            pageView: true,
          },
          page: {
            section: "sanow",
            name: window.location.pathname,
            language: lang,
            country: "glo",
            url: window.location.href,
            referrer: document.referrer,
          },
        });
      }

      await dispatch(siteCopyStore.shell);
      // await dispatch(inviteStore.load());
    } catch (e) {
      if (e instanceof InvalidEventError) {
        setEventNotFound(true);
      }
    } finally {
      setIsLoading(false);
    }
  });

  return { isLoading, event, eventNotFound };
}

export default useEventData;
