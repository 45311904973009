import React from "react";
import { useSelector } from "react-redux";

const footer = ({ language = "en" }) => {
  const { footerCopy } = useSelector((state) => state.siteCopy);

  return (
    <footer className="flex flex-col items-center">
      <div
        className="
      justify-center
      w-full
      flex
      flex-col
      items-center
      md:flex-row"
      >
        <div
          className="
        flex
        flex-col
        items-start
        py-[1.5rem]
        md:flex-row
        md:flex-wrap
        md:gap-x-[3.125rem]
        md:gap-y-[0.9375rem]

        custom-breakpoint-container

        lg:gap-x-[2.1875rem]
        lg:m-0
        lg:w-full
        lg:items-center"
        >
          <a
            target="_blank"
            href={
              footerCopy.footer && footerCopy.footer.privacyLinkUrl[language]
            }
            className="no-underline hover:underline w-full pb-[1.125rem] text-[0.75rem] flex text-[#4B4B4B] 
            md:py-0 md:w-fit
            lg:pb-0 lg:mr-0"
          >
            {footerCopy.footer && footerCopy.footer.privacyLink[language]}
          </a>
          <a
            target="_blank"
            href={
              footerCopy.footer &&
              footerCopy.footer.legalDisclosureLinkUrl[language]
            }
            className="no-underline hover:underline w-full py-[1.125rem] flex text-[0.75rem] text-[#4B4B4B] border-x-0 border-t-[1px] border-b-[0px] border-solid border-[#707070] 
            md:border-0 md:py-0 md:w-fit
            lg:pb-0 lg:mr-0"
          >
            {footerCopy.footer &&
              footerCopy.footer.legalDisclosureLink[language]}
          </a>
          <a
            target="_blank"
            href={
              footerCopy.footer && footerCopy.footer.copyrightLinkUrl[language]
            }
            className="no-underline hover:underline w-full py-[1.125rem] flex text-[0.75rem] text-[#4B4B4B] border-x-0 border-t-[1px] border-b-[0px] border-solid border-[#707070] 
            md:border-0 md:py-0 md:w-fit
            lg:pb-0 lg:mr-0"
          >
            {footerCopy.footer && footerCopy.footer.copyrightLink[language]}
          </a>
          <a
            target="_blank"
            href={
              footerCopy.footer && footerCopy.footer.tradeMarkLinkUrl[language]
            }
            className="no-underline hover:underline w-full py-[1.125rem] flex text-[0.75rem] text-[#4B4B4B] border-x-0 border-t-[1px] border-b-[0px] border-solid border-[#707070] 
            md:border-0 md:py-0 md:w-fit
            lg:pb-0 lg:mr-0"
          >
            {footerCopy.footer && footerCopy.footer.tradeMarkLink[language]}
          </a>
          <div
            id="teconsent"
            consent="0,1,2"
            aria-label="Open Cookie Preferences Modal"
            role="complementary"
            className="no-underline hover:underline w-full py-[1.125rem] flex text-[0.75rem] text-[#4B4B4B] border-x-0 border-t-[1px] border-b-[0px] border-solid border-[#707070] 
            md:border-0 md:py-0 md:w-fit
            lg:pb-0 lg:mr-0"
          ></div>
          {/* <a
            href={codeOfConductLinkUrl}
            className="w-full py-[1.125rem] flex text-[0.75rem] text-[#4B4B4B] border-x-0 border-t-[1px] border-b-[1px] border-solid border-[#707070] md:border-0 md:py-0 md:w-[100px] lg:pb-0 lg:mr-0 lg:w-fit"
          >
            Code Of Conduct
          </a> */}
        </div>
      </div>
    </footer>
  );
};

export default footer;
