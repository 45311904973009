import React from "react";
import { HashLink } from "react-router-hash-link";
import Card from "../../eclipse-ux/component/Card";
import PropTypes from "prop-types";
import Button from "../../eclipse-ux/component/Button";
import CardImg1 from "../assets/visitCardImg1.jpg";
import CardImg2 from "../assets/visitCardImg2.jpg";
import CardImg3 from "../assets/visitCardImg3.jpg";

const PlanYourVisit = ({
  PlanYourVisit_Header,

  PlanYourVisit_CardHeader_1,
  PlanYourVisit_CardParagraph_1,
  PlanYourVisit_CardButtonURL_1,
  PlanYourVisit_CardButtonText_1,

  PlanYourVisit_CardHeader_2,
  PlanYourVisit_CardParagraph_2,
  PlanYourVisit_CardButtonURL_2,
  PlanYourVisit_CardButtonText_2,

  PlanYourVisit_CardHeader_3,
  PlanYourVisit_CardParagraph_3,
  PlanYourVisit_CardButtonURL_3,
  PlanYourVisit_CardButtonText_3,
}) => {
  return (
    <div className="w-full flex flex-col items-center bg-gradient-to-b from-[rgba(232,231,231,1)] via-[rgba(232,231,231,0)] to-[rgba(232,231,231,1)]">
      <div className="custom-breakpoint-container my-[3.125rem] lg:my-[7.5rem]">
        <h1 className=" text-[1.7281rem] md:text-[2.8563rem] text-center">
          {PlanYourVisit_Header}
        </h1>
        <div
          className="
        w-full md:w-[27.5rem] m-auto lg:w-auto
        flex flex-col items-center lg:flex-row 
        mt-[3.75rem]"
        >
          <Card
            className="
          pb-[2.5rem] 
          w-full
          shadow-[0px_3px_6px_0px_rgba(0,0,0,0.16)]"
          >
            <img
              className="w-full"
              src={CardImg1}
              alt="plan your visit image"
            />
            <div className="flex flex-col px-[1.5rem] items-center">
              <h2 dangerouslySetInnerHTML={{__html: PlanYourVisit_CardHeader_1}} className="text-[1.2rem] md:text-[2.1969rem] md:mt-7 mt-5 mb-0">
              </h2>

              <p className="text-[1rem] md:text-[1.3rem] md:mb-10 md:mt-5 mb-5 mt-[0.625rem]">
                {PlanYourVisit_CardParagraph_1}
              </p>
              <Button
                onClick={() => {
                  location.href = PlanYourVisit_CardButtonURL_1;
                }}
                className="self-center sap-btn hidden md:flex w-fit"
              >
                {PlanYourVisit_CardButtonText_1}
              </Button>
              <a
                className="self-center flex md:hidden text-[#2A6B92]  no-underline font-light"
                href={PlanYourVisit_CardButtonURL_1}
              >
                {PlanYourVisit_CardButtonText_1}
              </a>
            </div>
          </Card>

          <Card
            className="pb-[2.5rem]
          w-full
          my-[1.25rem] md:my-[2.5rem] lg:my-0 
          lg:mx-5 
          shadow-[0px_3px_6px_0px_rgba(0,0,0,0.16)]"
          >
            <img
              className="w-full"
              src={CardImg2}
              alt="plan your visit image"
            />
            <div className="flex flex-col px-[1.5rem] items-center">
              <h2 dangerouslySetInnerHTML={{__html: PlanYourVisit_CardHeader_2}} className="text-[1.2rem] md:text-[2.1969rem] md:mt-7 mt-5 mb-0">
              </h2>

              <p className="text-[1rem] md:text-[1.3rem] md:mb-10 md:mt-5 mb-5 mt-[0.625rem]">
                {PlanYourVisit_CardParagraph_2}
              </p>
              <Button
                onClick={() => {
                  location.href = PlanYourVisit_CardButtonURL_2;
                }}
                className="self-center sap-btn hidden md:flex w-fit"
              >
                {PlanYourVisit_CardButtonText_2}
              </Button>
              <a
                className="self-center flex md:hidden text-[#2A6B92]  no-underline font-light"
                href={PlanYourVisit_CardButtonURL_2}
              >
                {PlanYourVisit_CardButtonText_2}
              </a>
            </div>
          </Card>

          <Card
            className="pb-[2.5rem]
          w-full
          shadow-[0px_3px_6px_0px_rgba(0,0,0,0.16)]"
          >
            <img
              className="w-full aspect-[3/2]"
              src={CardImg3}
              alt="plan your visit image"
            />
            <div className="flex flex-col px-[1.5rem] items-center">
              <h2 dangerouslySetInnerHTML={{__html: PlanYourVisit_CardHeader_3}} className="text-[1.2rem] md:text-[2.1969rem] md:mt-7 mt-5 mb-0">
              </h2>

              <p className="text-[1rem] md:text-[1.3rem] md:mb-10 md:mt-5 mb-5 mt-[0.625rem]">
                {PlanYourVisit_CardParagraph_3}
              </p>
              {PlanYourVisit_CardButtonURL_3 && (
                <HashLink
                  className="sap-btn hidden md:flex w-fit no-underline"
                  to={PlanYourVisit_CardButtonURL_3}
                >
                  <span className="self-center">
                    {" "}
                    {PlanYourVisit_CardButtonText_3}
                  </span>
                </HashLink>
              )}
              {PlanYourVisit_CardButtonURL_3 && (
                <HashLink
                  className="self-center flex md:hidden text-[#2A6B92]  no-underline font-light"
                  to={PlanYourVisit_CardButtonURL_3}
                >
                  {PlanYourVisit_CardButtonText_3}
                </HashLink>
              )}
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

PlanYourVisit.propTypes = {
  PlanYourVisit_Header: PropTypes.string,

  PlanYourVisit_CardHeader_1: PropTypes.string,
  PlanYourVisit_CardParagraph_1: PropTypes.string,
  PlanYourVisit_CardButtonURL_1: PropTypes.string,
  PlanYourVisit_CardButtonText_1: PropTypes.string,

  PlanYourVisit_CardHeader_2: PropTypes.string,
  PlanYourVisit_CardParagraph_2: PropTypes.string,
  PlanYourVisit_CardButtonURL_2: PropTypes.string,
  PlanYourVisit_CardButtonText_2: PropTypes.string,

  PlanYourVisit_CardHeader_3: PropTypes.string,
  PlanYourVisit_CardParagraph_3: PropTypes.string,
  PlanYourVisit_CardButtonURL_3: PropTypes.string,
  PlanYourVisit_CardButtonText_3: PropTypes.string,
};

export default PlanYourVisit;
