import React from "react";
import refreshingWalkImg from "../assets/refreshingWalkImg.jpg";
import PropTypes from "prop-types";

const RefreshingWalk = ({
  WalkPark_Header,
  WalkPark_TopParagraph,
  WalkPark_ListItem_1,
  WalkPark_ListItem_2,
  WalkPark_ListItem_3,
  WalkPark_ListItem_4,
  WalkPark_BottomParagraph,
}) => {
  return (
    <div
      className="gradient

    flex
    flex-col
    items-center
  "
    >
      <div className="custom-breakpoint-container">
        {/* container */}

        <div
          className="
        bg-white
        shadow-[0_1.1875rem_2.375rem_#00000029]
      flex
      flex-col
      md:my-[4.375rem]
      xl:flex-row
      my-[3.125rem]
      xl:my-[7.5rem] 
      "
        >
          <div className="flex w-full">
            <div
              style={{
                backgroundImage: `url(${refreshingWalkImg})`,
              }}
              className="
                hidden
                1280px:flex
                flex-shrink-0
                bg-center
                bg-cover
                w-full
                h-full"
            ></div>
          </div>

          <div className="1280px:hidden">
            <img className="w-full" src={refreshingWalkImg} />
          </div>

          <div
            className="
        flex
        flex-col   

        items-start
        md:mb-[3.625rem]
        px-[0.625rem]
        mx-[0.625rem]
        md:mx-[2.625rem]
        lg:ml-[4.375rem]
        lg:mr-[7.1875rem]
        "
          >
            <h2 className="mt-[1.25rem] md:text-[2.1875rem] md:mt-[1.875rem] lg:mt-[3.75rem] mb-[1.25rem] lg:mb-[1.875rem]">
              {WalkPark_Header}
            </h2>
            <p className="mb-[1.25rem] md:text-[1.25rem] md:leading-[1.9375rem]">
              {WalkPark_TopParagraph}
            </p>
            <ul className="pl-4 m-0">
              {WalkPark_ListItem_1 && (
                <li className="py-1 lg:py-[14px] lg:text-[1.25rem] lg:leading-[1.9375rem]">
                  {WalkPark_ListItem_1}
                </li>
              )}
              {WalkPark_ListItem_2 && (
                <li className="py-1 lg:py-[14px] lg:text-[1.25rem] lg:leading-[1.9375rem]">
                  {WalkPark_ListItem_2}
                </li>
              )}
              {WalkPark_ListItem_3 && (
                <li className="py-1 lg:py-[14px] lg:text-[1.25rem] lg:leading-[1.9375rem]">
                  {WalkPark_ListItem_3}
                </li>
              )}
              {WalkPark_ListItem_4 && (
                <li className="py-1 lg:py-[14px] lg:text-[1.25rem] lg:leading-[1.9375rem]">
                  {WalkPark_ListItem_4}
                </li>
              )}
            </ul>

            <p className="mb-[1.75rem] mt-[1.25rem]">
              {WalkPark_BottomParagraph}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

RefreshingWalk.propTypes = {
  WalkPark_Header: PropTypes.string,
  WalkPark_TopParagraph: PropTypes.string,
  WalkPark_ListItem_1: PropTypes.string,
  WalkPark_ListItem_2: PropTypes.string,
  WalkPark_ListItem_3: PropTypes.string,
  WalkPark_ListItem_4: PropTypes.string,
  WalkPark_BottomParagraph: PropTypes.string,
};

export default RefreshingWalk;

/*

*/
