import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import DataCard from "./components/dataCard/dataCard";
import DataCard2 from "./components/dataCard/dataCard2";
import Questions from "./components/questions/questions";
import ContactUs from "./components/contactUs/contactUs";

import FAQBar from "./components/faqBar/faqBar";
import LargeCard from "./components/largeDataCard/largeDataCard";
import ScrollToTopSection from "../app/component/scrollToTopSection";
import Hero from "../app/component/SimpleHeroBanner";

const processFaqCopy = (faqData, maxLoops, language) => {
  let processedData = [];
  let loopCount = 1;
  while (loopCount <= maxLoops) {
    let categoryObj = {};
    let itemCount = 0;
    Object.entries(faqData).forEach((keyValArray) => {
      if (keyValArray[0].startsWith(`category${loopCount}`)) {
        if (keyValArray[0].includes("Item")) {
          itemCount++;
          categoryObj[
            `faq_category${loopCount}Item${keyValArray[0].split("Item")[1]}`
          ] = keyValArray[1][language];
        } else {
          categoryObj[`faq_category${loopCount}Title`] =
            keyValArray[1][language];
        }
      }
    });
    categoryObj["itemCount"] = itemCount / 2;
    processedData[loopCount - 1] = categoryObj;
    loopCount++;
  }
  return {
    faq_MainTitle: faqData["MainTitle"][language],
    itemArray: processedData,
  };
};

const LogisticsPage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { venueFaqCopy } = useSelector((state) => state.siteCopy);
  const articleRef = useRef();

  function handleCovidClick() {
    articleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  useMount(async () => {
    await dispatch(siteCopyStore.venueFaq);
  });

  let processedFaqCopy;
  if (Object.keys(venueFaqCopy).length > 0) {
    processedFaqCopy = processFaqCopy(venueFaqCopy.faq, 4, language);
    Object.freeze(processedFaqCopy);
  }

  return (
    <>
    <div className="">
      <Hero
        siteCopy={venueFaqCopy.hero && venueFaqCopy.hero.mainText[language]}
        subText={venueFaqCopy.hero && venueFaqCopy.hero.subText[language]}
        buttonText={venueFaqCopy.hero && venueFaqCopy.hero.btnText[language]}
        buttonURL={venueFaqCopy.hero && venueFaqCopy.hero.btnUrl[language]}
      ></Hero>
      </div>
      <LargeCard
        venue_mainText={
          venueFaqCopy.venue && venueFaqCopy.venue.mainText[language]
        }
        venue_photoUrl={
          venueFaqCopy.venue && venueFaqCopy.venue.photoUrl[language]
        }
        venue_venueDirectionsBtn={
          venueFaqCopy.venue && venueFaqCopy.venue.venueDirectionsBtn[language]
        }
        venue_venueDirectionsBtnURL={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.venueDirectionsBtnURL[language]
        }
        venue_section1Title={
          venueFaqCopy.venue && venueFaqCopy.venue.section1Title[language]
        }
        venue_section1Description1={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section1Description1[language]
        }
        venue_section1Description2={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section1Description2[language]
        }
        venue_section1LinkText={
          venueFaqCopy.venue && venueFaqCopy.venue.section1LinkText[language]
        }
        venue_section1LinkURL={
          venueFaqCopy.venue && venueFaqCopy.venue.section1LinkURL[language]
        }
        venue_section2Title={
          venueFaqCopy.venue && venueFaqCopy.venue.section2Title[language]
        }
        venue_section2Description1={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section2Description1[language]
        }
        venue_section2LinkText={
          venueFaqCopy.venue && venueFaqCopy.venue.section2LinkText[language]
        }
        venue_section2LinkURL={
          venueFaqCopy.venue && venueFaqCopy.venue.section2LinkURL[language]
        }
        venue_section3Title={
          venueFaqCopy.venue && venueFaqCopy.venue.section3Title[language]
        }
        venue_section3Description1={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section3Description1[language]
        }
        venue_section3Description2={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section3Description2[language]
        }
        venue_section3Description3={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section3Description3[language]
        }
        venue_section3LinkText={
          venueFaqCopy.venue && venueFaqCopy.venue.section3LinkText[language]
        }
        venue_section3LinkURL={
          venueFaqCopy.venue && venueFaqCopy.venue.section3LinkURL[language]
        }
        venue_section4Title={
          venueFaqCopy.venue && venueFaqCopy.venue.section4Title[language]
        }
        venue_section4Description1={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section4Description1[language]
        }
        venue_section4Description2={
          venueFaqCopy.venue &&
          venueFaqCopy.venue.section4Description2[language]
        }
        venue_section4LinkText={
          venueFaqCopy.venue && venueFaqCopy.venue.section4LinkText[language]
        }
        venue_section4LinkURL={
          venueFaqCopy.venue && venueFaqCopy.venue.section4LinkURL[language]
        }
      ></LargeCard>
      {venueFaqCopy.hotelBooking &&
        venueFaqCopy.hotelBooking.mainTitle[language] && (
          <DataCard
            hotelBooking_mainTitle={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.mainTitle[language]
            }
            hotelBooking_description={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.description[language]
            }
            hotelBooking_mapBtn={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.mapBtn[language]
            }
            hotelBooking_mapBtnURL={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.mapBtnURL[language]
            }
            hotelBooking_registrationBtn={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.registrationBtn[language]
            }
            hotelBooking_registrationBtnURL={
              venueFaqCopy.hotelBooking &&
              venueFaqCopy.hotelBooking.registrationBtnURL[language]
            }
          ></DataCard>
        )}
      {venueFaqCopy.registrationPolicy &&
        venueFaqCopy.registrationPolicy.registrationPolicyTitle[language] && (
          <DataCard2
            registrationPolicy_registrationPolicyRates={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyRates[language]
            }
            registrationPolicy_registrationPolicyRatesValues={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyRatesValues[
                language
              ]
            }
            registrationPolicy_registrationPolicyTitle={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyTitle[language]
            }
            registrationPolicy_registrationPolicyDescription={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyDescription[
                language
              ]
            }
            registrationPolicy_registrationPolicyBtn={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyBtn[language]
            }
            registrationPolicy_registrationPolicyBtnURL={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.registrationPolicyURL[language]
            }
            registrationPolicy_internationalAttendeesTitle={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.internationalAttendeesTitle[
                language
              ]
            }
            registrationPolicy_internationalAttendeesDescription={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy.internationalAttendeesDescription[
                language
              ]
            }
            registrationPolicy_internationalAttendeesRegistrationBtn={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy
                .internationalAttendeesRegistrationBtn[language]
            }
            registrationPolicy_internationalAttendeesRegistrationBtnURL={
              venueFaqCopy.registrationPolicy &&
              venueFaqCopy.registrationPolicy
                .internationalAttendeesRegistrationBtnURL[language]
            }
          ></DataCard2>
        )}
         {venueFaqCopy.faq &&
        venueFaqCopy.faq.MainTitle[language] && 
      <Questions
        siteCopy={processedFaqCopy}
        handleCovidClick={handleCovidClick}
      />
         }
      <ContactUs
        contactUs_title={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.title[language]
        }
        contactUs_card1Title={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card1Title[language]
        }
        contactUs_card1Email={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card1Email[language]
        }
        contactUs_card1EmailUrl={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card1EmailUrl[language]
        }
        contactUs_card1hoursDescription={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card1hoursDescription[language]
        }
        contactUs_card1OperatingHours={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card1OperatingHours[language]
        }
        contactUs_card1OperatingDays={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card1OperatingDays[language]
        }
        contactUs_card2Title={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card2Title[language]
        }
        contactUs_card2Email={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card2Email[language]
        }
        contactUs_card2EmailUrl={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card2EmailUrl[language]
        }
        contactUs_card2hoursDescription={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card2hoursDescription[language]
        }
        contactUs_card2OperatingHours={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card2OperatingHours[language]
        }
        contactUs_card2OperatingDays={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card2OperatingDays[language]
        }
        contactUs_card3Title={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card3Title[language]
        }
        contactUs_card3Email={
          venueFaqCopy.contactUs && venueFaqCopy.contactUs.card3Email[language]
        }
        contactUs_card3EmailUrl={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3EmailUrl[language]
        }
        contactUs_card3PhoneNumber={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3PhoneNumber[language]
        }
        contactUs_card3IntPhoneNumber={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3IntPhoneNumber[language]
        }
        contactUs_card3hoursDescription={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3hoursDescription[language]
        }
        contactUs_card3OperatingHours={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3OperatingHours[language]
        }
        contactUs_card3OperatingDays={
          venueFaqCopy.contactUs &&
          venueFaqCopy.contactUs.card3OperatingDays[language]
        }
      ></ContactUs>

      <FAQBar
        ref={articleRef}
        covidQuestions_mainText={
          venueFaqCopy.covidQuestions &&
          venueFaqCopy.covidQuestions.mainText[language]
        }
        covidQuestions_subText={
          venueFaqCopy.covidQuestions &&
          venueFaqCopy.covidQuestions.subText[language]
        }
        covidQuestions_subText2={
          venueFaqCopy.covidQuestions &&
          venueFaqCopy.covidQuestions.subText2[language]
        }
        covidQuestions_subText3={
          venueFaqCopy.covidQuestions &&
          venueFaqCopy.covidQuestions.subText3[language]
        }
        covidQuestions_viewFAQBtn={
          venueFaqCopy.covidQuestions &&
          venueFaqCopy.covidQuestions.viewFAQBtn[language]
        }
      ></FAQBar>
      <ScrollToTopSection />
    </>
  );
};

export default LogisticsPage;
