import React from "react";
import BusinessNeedsCard from "./BusinessNeedsCard";
import card1Gradient from "../assets/card1-gradient.svg";
import card2Gradient from "../assets/card2-gradient.svg";
import card3Gradient from "../assets/card3-gradient.svg";
import card4Gradient from "../assets/card4-gradient.svg";
import card5Gradient from "../assets/card5-gradient.svg";
import card6Gradient from "../assets/card6-gradient.svg";
import card7Gradient from "../assets/card7-gradient.svg";
import card8Gradient from "../assets/card8-gradient.svg";

import card1Photo from "../assets/card1-photo-mobile.png";
import card2Photo from "../assets/card2-photo-mobile.png";
import card3Photo from "../assets/card3-photo-mobile.png";
import card4Photo from "../assets/card4-photo-mobile.png";
import card5Photo from "../assets/card5-photo-mobile.png";
import card6Photo from "../assets/card6-photo-mobile.png";
import card7Photo from "../assets/card7-photo-mobile.png";
import card8Photo from "../assets/card8-photo-mobile.png";

const photos = [
  card1Photo,
  card2Photo,
  card3Photo,
  card4Photo,
  card5Photo,
  card6Photo,
  card7Photo,
  card8Photo,
];
const gradients = [
  card1Gradient,
  card2Gradient,
  card3Gradient,
  card4Gradient,
  card5Gradient,
  card6Gradient,
  card7Gradient,
  card8Gradient,
];

const BusinessNeeds = ({ siteCopy, language }) => {
  const processData = (data) => {
    if (siteCopy) {
      let sessions = [];
      let count = 1;
      while (count < 9) {
        let tempObject = {};
        Object.entries(data).forEach((entry) => {
          if (entry[0].startsWith("session" + count)) {
            tempObject[entry[0]] = entry[1];
          }
        });
        sessions[count - 1] = tempObject;
        count++;
      }
      return sessions;
    }
  };

  const processedData = processData(siteCopy);

  return (
    <div
      className="
    flex
    flex-col
    items-center
    lg:py-[7.5rem]
    md:py-[4.375rem]
    py-[3.125rem]
    px-[1.25rem]
    gap-[1.25rem]
    gradient
    "
    >
      <div className="flex flex-col items-center mb-[2.5rem] md:mb-[4rem] lg:mb-[5.625rem] max-w-[17.5rem] gap-[1.25rem] md:max-w-full">
        <div
          className="
        leading-[1.875rem]
        text-[1.4375rem]
        md:text-center
        md:text-[2.875rem]
        md:leading-[3.4375rem]
        lg:text[2.8563rem]
        xl:text[2.8563rem]
        
        "
        >
          {siteCopy && siteCopy.mainText[language]}
        </div>

        <div className="text-[#545454] text-[1rem] leading-[1.5rem] md:text-center lg:max-w-[68rem]" dangerouslySetInnerHTML={{__html: siteCopy && siteCopy.subText[language]}}>
        </div>
      </div>
      {siteCopy && siteCopy["session1ShowLess"][language] &&
        ["1", "2", "3", "4", "5", "6", "7", "8"].map((number, index) => {
          //this plu 1 is because the spread sheet does not start at 0
          if(processedData[index][`session${index +1}Title`][language])
          return (
            <BusinessNeedsCard
              key={index}
              gradient={gradients[index]}
              photo={photos[index]}
              siteCopy={processedData[index]}
              language={language}
              index={index}
            />
          );
        })}
    </div>
  );
};

export default BusinessNeeds;
