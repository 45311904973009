import React from "react";
import PropTypes from "prop-types";

const CentralShowcase = ({
  CentralShowcase_Header,
  CentralShowcase_Paragraph,
}) => {
  return (
    <div
      id="centralShowCaseWrapper"
      className="w-full flex items-center justify-center bg-center lg:bg-right bg-cover"
    >
      <div className="custom-breakpoint-container my-[3.125rem] md:my-[4.375rem] lg:my-[7.5rem]">
        <div className="chro fire overflow-hidden relative fire__chro
        shadow-[0px_19px_38px_5px_rgba(0,0,0,0.16)] 
        flex justify-center flex-col
        py-[2.5rem] px-[1.25rem] md:px-[1.875rem] md:w-[25.75rem] lg:w-[41.875rem]
        ">
          <h2 className="relative z-10 m-0 pb-[1.875rem]">
            {CentralShowcase_Header}
          </h2>
          <p className="relative z-10 m-0">{CentralShowcase_Paragraph}</p>
        </div>
      </div>
    </div>
  );
};

CentralShowcase.propTypes = {
  CentralShowcase_Header: PropTypes.string,
  CentralShowcase_Paragraph: PropTypes.string,
};

export default CentralShowcase;
