import React from "react";
import ScrollToTop from "../../eclipse-ux/component/ScrollButton";

const scrollToTopSection = () => {
  return (
    <div
     style={{
        backgroundImage:"linear-gradient(180deg, rgba(255,255,255,1) 70%, rgba(232,231,231,1) 100%)"
     }}
      className="flex-row lg:hidden w-full justify-end items-center flex gradient-bottom"
    >
      <ScrollToTop
        className="w-[1.875rem] h-[1.875rem] rounded border-solid border-[0.0625rem]
            hover:pointer-events-auto bg-sap-white-FFFFFF border-[#424242] my-5 mx-[1.375rem] hover:fill-[#ffffff] hover:bg-[#424242]"
      />
    </div>
  );
};

export default scrollToTopSection;
