import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import banner_background from "../app/asset/Banner-Background.svg";
import BusinessNeedsCard from "./components/BusinessNeedsCard";

import "./style.css";

const CelebrationNightPage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { celebrationNightCopy } = useSelector((state) => state.siteCopy);

  useMount(async () => {
    await dispatch(siteCopyStore.celebrationNight);
  });

  return (
    <div className="flex flex-col">
      {/*  format like homepage hero */}
      {/* adjust sizeing to match XD can use faq as reference*/}
      {celebrationNightCopy.hero && (
        <div
          className="grid grid-cols-2 w-full h-[240px] md:h-[300px]"
          style={{ backgroundImage: `url(${banner_background})` }}
        >
          <div className="flex flex-col col-span-2 items-center text-center md:text-left md:col-span-1 lg:items-start justify-center ml-auto w-full max-w-[715px] py-10 md:py-0 px-12">
            <div className="md:max-w-[360px] desktop:max-w-none">
              <h1 className="font-normal m-0 text-[1.44rem] leading-[1.869rem] md:text-[1.688rem] md:leading-[2.197rem] desktop:text-[2.856rem] desktop:leading-[3.428rem]">
                {celebrationNightCopy.hero.mainText[language]}
              </h1>
              {celebrationNightCopy.hero.subText[language] && (
                <h3
                  className="font-normal m-0 pt-2 desktop:text-[1.69rem] md:text-[1.3rem] text-[1.2rem]"
                  dangerouslySetInnerHTML={{
                    __html: celebrationNightCopy.hero.subText[language],
                  }}
                ></h3>
              )}
            </div>
          </div>
          <div
            className="hidden md:block justify-self-end md:w-[400px] lg:w-[635px] bg-center bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${celebrationNightCopy.hero.imgUrl[language]})`,
            }}
          ></div>
        </div>
      )}
      {celebrationNightCopy.fun && (
        <div className="silverBG">
          <div className="desktop:my-[7.5rem] md:my-16 my-12 custom-breakpoint-container flex flex-col items-center">
            <img
              src={celebrationNightCopy.fun.imgUrl[language]}
              alt=""
              className="w-full desktop:mb-[3.75rem] md:mb-10 h-[15.188rem] object-cover sm:h-auto"
            />
           
            <h1 className="font-normal max-w-[15rem] md:max-w-none text-center text-[1.728rem] leading-[2.074rem] desktop:text-[2.856rem] desktop:leading-[3.428rem] desktop:mb-7 md:text-[2.197rem] md:leading-[2.636rem]">
              {celebrationNightCopy.fun.mainText[language]}
            </h1>
            <div className="grid desktop:grid-cols-2 grid-flow-row desktop:gap-[2.25rem] text-sap-black-545454 text-base md:max-w-[37.5rem] desktop:max-w-none">
              <p
                dangerouslySetInnerHTML={{
                  __html: celebrationNightCopy.fun.column1[language],
                }}
              ></p>

              <p
                dangerouslySetInnerHTML={{
                  __html: celebrationNightCopy.fun.column2[language],
                }}
              ></p>
            </div>
          </div>
        </div>
      )}

      {/*copy needs to be adjusted so there's a </br> between the where and when on the card
      also everything needs to be placed within a svg file with z at -1
      <div className="bg-sap-black-545454">
        <div className="custom-breakpoint-container flex flex-col items-center desktop:py-[7.5rem] md:py-[4.375rem] py-12">
          {celebrationNightCopy.schedule && (
            <BusinessNeedsCard
              photo={celebrationNightCopy.schedule.imgUrl[language]}
              photo2={celebrationNightCopy.schedule.imgUrlTablet[language]}
              siteCopy={celebrationNightCopy.schedule}
              language={language}
              index={0}
            />
          )}
        </div>
      </div>*/}
      {/* pull footer from anywhere */}
      <div className="flex flex-col items-center justify-center desktop:py-[3.813rem] md:py-10 py-7 w-full">
        {celebrationNightCopy.footer && (
          <div className="max-w-[19rem] md:max-w-[37.5rem] desktop:max-w-none">
            <h1
              className="md:text-[1.69rem] text-center text-[1.44rem] font-normal"
              dangerouslySetInnerHTML={{
                __html: celebrationNightCopy.footer.mainText[language],
              }}
            ></h1>
          </div>
        )}
      </div>
    </div>
  );
};

export default CelebrationNightPage;
