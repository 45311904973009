import React from "react";
import ExperienceSubCard from "./ExperiencSubCard";
import BannerBackground from "../../app/asset/orangeBG.png";

const Experiences = ({ siteCopy, language }) => {
  let subCardCopy = [];
  if (siteCopy) {
    let sectionCount = 1;
    while (sectionCount < 4) {
      if (siteCopy[`section${sectionCount}MainDescription`]) {
        let sectionObj = {
          header: siteCopy[`section${sectionCount}MainDescription`],
        };
        let experienceCount = 1;
        let expArray = [];
        while (experienceCount < 4) {
          //check if the experience exist
          if (
            siteCopy[`section${sectionCount}Experience${experienceCount}Title`]
          ) {
            let bodyObj = {
              p1: siteCopy[
                `section${sectionCount}Experience${experienceCount}Title`
              ],
              p2: siteCopy[
                `section${sectionCount}Experience${experienceCount}Duration`
              ],
              p3: siteCopy[
                `section${sectionCount}Experience${experienceCount}Description`
              ],
            };
            expArray[experienceCount - 1] = bodyObj;
          }
          experienceCount++;
        }
        sectionObj.body = expArray;
        subCardCopy[sectionCount - 1] = sectionObj;
      }
      sectionCount++;
    }
  }

  //init render boolean
  let renderComponent = false;
  //only run on munich site
  if(language == 'de-Munich'){
    //loop over copy. If any text for munich present, change bool to render component
    for(const label in siteCopy){
      if(!siteCopy[label]['de-Munich'] == '' && !siteCopy[label]['de-Munich'] == undefined){
          renderComponent = true;
      }
    }
    //if no copy for munich is set, do not render component
    if(!renderComponent){return(null)}
  }
  
  return (
    <div
      className="lg:flex lg:justify-center lg:py-[7.5rem]"
      style={{ backgroundImage: `url(${BannerBackground})` }}
    >
      <div
        className="
            flex
            flex-col
            items-center
            py-[3.125rem]
            px-[1.25rem]
            xl:py-[3.75rem]
            xl:px-0
            xl:m-[0.625rem]
            xl:w-[1360px]         
            "
      >
        <div className="flex flex-col items-center mb-[3.125rem] xl:mb-[58px]">
          <div
            className="
                    mb-[0.9375rem]
                    md:mb-[1.25rem]
                    lg:mb-[1.25rem]
                    xl:mb-[1.875rem]
                    text-[1.44rem]
                    md:text-[2.197rem]
                    md:leading-[2.636rem]
                    leading-[1.875rem]
                    font-normal
                    md:text-center
                    md:max-w-[37.5rem]
                    lg:max-w-[56.25rem]
                    "
          >
            {siteCopy && siteCopy.mainText[language]}
          </div>

          <div
            className="md:max-w-[37.5rem] lg:max-w-[56.25rem]  text-[1.3rem] leading-[1.95rem]
          md:text-center text-[#545454]"
          >
            <p>{siteCopy && siteCopy.subText[language]}</p>
          </div>
        </div>
        <div className="flex flex-col space-y-[3.125rem]">
          {siteCopy &&
            subCardCopy.map((section, index) => {
              if (section.header && section.header[language])
                return (
                  <ExperienceSubCard
                    key={index}
                    siteCopy={section}
                    language={language}
                    index={index}
                  />
                );
            })}
        </div>
      </div>
    </div>
  );
};

export default Experiences;
