import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
// import AgendaBanner from "./components/AgendaBanner";
import AgendaBanner from "../app/component/SimpleHeroBanner";

import CardList from "./components/CardList";
import Schedule from "./components/Schedule";
import AgendaFooter from "./components/AgendaFooter";
import ViewSessionCatalog from "../app/component/ViewSessionCatalog";
import ScrollToTopSection from "./components/scrollToTopSection";

const processCardData = (data,lang) => {
  let processedData = {}
  let tabBool = false
  Object.entries(data).forEach(keyValPair => {
    processedData[keyValPair[0]] = Object.keys(keyValPair[1]).length > 0 ? keyValPair[1][lang] : ''
    if(keyValPair[0] == "tab2Title"){
      tabBool = true
    }
  })
  return [processedData,tabBool]
}

const AgendaPage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { agendaCopy } = useSelector((state) => state.siteCopy);

  useMount(async () => {
    await dispatch(siteCopyStore.agenda);
  });

  let processedCardData
  let tabCountMoreThanOne = false
  if(Object.keys(agendaCopy).length > 0){
    let results = processCardData(agendaCopy.agenda,language)
    processedCardData = results[0]
    tabCountMoreThanOne = results[1]
    Object.freeze(processedCardData)
  }
  return (
    <div className="">
      <AgendaBanner
        siteCopy={agendaCopy.hero && agendaCopy.hero.mainText[language]}
      />
      {agendaCopy.agenda && (
          <CardList siteCopy = {agendaCopy && processedCardData} lang = {language} tabCountMoreThanOne = {tabCountMoreThanOne}/>
      )}


      {/* <ViewSessionCatalog
        siteCopy={{
          title:
            agendaCopy.learnMore && agendaCopy.learnMore.mainText[language],
          subTitle: "",
          btnText:
            agendaCopy.learnMore &&
            agendaCopy.learnMore.sessionTypesButton[language],
          buttonURL:
            agendaCopy.learnMore &&
            agendaCopy.learnMore.sessionTypesButtonURL[language],
        }}
      /> */}
      <ScrollToTopSection />
    </div>
  );
};

export default AgendaPage;