import React from "react";
import AgendaCard from "./AgendaCard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const TAB_COUNT = 5;
const EVENT_COUNT = 15;

const getShortDay = (longDay) => {
  const dayMapping = {
    Monday: "Mon",
    Tuesday: "Tue",
    Wednesday: "Wed",
    Thursday: "Thurs",
    Friday: "Fri",
    Saturday: "Sat",
    Sunday: "Sun",
  };
  return dayMapping[longDay];
};

const getTab = (day) => {
  // const theme = useTheme();
  // const smallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // let dayArray = day.split(' ')

  return (
    <div>
      <p
        dangerouslySetInnerHTML={{ __html: day }}
        className="m-0 text-black text-[1.2rem] leading-[1.5562rem] md:text-[1.25rem] md:leading-[1.5rem]"
      ></p>
      {/* <p className="m-0 text-black text-[0.8331rem] leading-[1.0831rem] md:text-[1.125rem] md:leading-[1.5rem]">
        {`${dayArray[1]} ${dayArray[2]}`}
      </p> */}
    </div>
  );
};

const renderCard = (selected, siteCopy) => {
  let sessionData = [];
  Array.from(Array(EVENT_COUNT).keys()).forEach((index) => {
    if (siteCopy[`tab${selected + 1}Event${index + 1}Title`]) {
      sessionData[index] = {
        time: siteCopy[`tab${selected + 1}Event${index + 1}Time`],
        sessions: [
          {
            title: siteCopy[`tab${selected + 1}Event${index + 1}Title`],
            speaker: siteCopy[`tab${selected + 1}Event${index + 1}Speaker`],
            floorCloseDisclaimer:
              siteCopy[`tab${selected + 1}Event${index + 1}Description`],
          },
        ],
      };
    }
  });

  return sessionData.map((data, index) => {
    return <AgendaCard key={index} sessionData={data} />;
  });
};

// const renderTabs = (tabCountBool,siteCopy,lang) => {
// if(tabCountBool){

// }else{
//   return Array.from(Array(1).keys()).map(number => {
//     if(siteCopy[`tab${number+1}Title`]){
//       return <Tab key={number+1} className="normal-case" disableRipple label={getTab(siteCopy[`tab${number+1}Title`], lang)} />
//     }
//   })
// }
// }

const CardList = ({ siteCopy, lang, tabCountMoreThanOne }) => {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
      <div className="flex flex-col w-full h-[200] bg-gradient-to-b from-[rgba(232,231,231,1)] to-[rgba(232,231,231,0)] md:h-[380] lg:h-[450] ">
        {/* <div className="flex"> */}
        {/* <p className="flex justify-center mt-[3.125rem] mb-[0rem] text-[1.5rem] leading-[1.875rem] md:mt-[4.375rem] md:text-[2.1969rem] md:leading-[2.6362rem] lg:mt-[7.5rem] lg:text-[2.8563rem] lg:leading-[3.425rem]">
          Agenda
        </p> */}
        <div className="flex justify-center mt-[3.125rem] md:mt-[3.75rem] lg:mt-[5rem]">
          <Tabs
            TabIndicatorProps={{
              sx: {
                bgcolor: "black",
              },
            }}
            value={selectedTab}
            onChange={handleChange}
          >
            {/* {Array.from(Array(TAB_COUNT).keys()).map(number => {
              if(siteCopy[`tab${number+1}Title`]){
                return <Tab key={number+1} className="normal-case" disableRipple label={getTab(siteCopy[`tab${number+1}Title`], lang)} />
              }
            })} */}
            {siteCopy[`tab${1}Title`] && (
              <Tab
                className="normal-case"
                disableRipple
                label={getTab(siteCopy[`tab${1}Title`])}
              />
            )}
            {siteCopy[`tab${2}Title`] && (
              <Tab
                className="normal-case"
                disableRipple
                label={getTab(siteCopy[`tab${2}Title`])}
              />
            )}
            {siteCopy[`tab${3}Title`] && (
              <Tab
                className="normal-case"
                disableRipple
                label={getTab(siteCopy[`tab${3}Title`])}
              />
            )}
            {siteCopy[`tab${4}Title`] && (
              <Tab
                className="normal-case"
                disableRipple
                label={getTab(siteCopy[`tab${4}Title`])}
              />
            )}
            {siteCopy[`tab${5}Title`] && (
              <Tab
                className="normal-case"
                disableRipple
                label={getTab(siteCopy[`tab${5}Title`])}
              />
            )}
            {/* <Tab key={number+1} className="normal-case" disableRipple label={getTab(siteCopy[`tab${2}Title`], lang)} />
            <Tab key={number+1} className="normal-case" disableRipple label={getTab(siteCopy[`tab${3}Title`], lang)} /> */}
          </Tabs>
        </div>
        {/* </div> */}
      </div>
      <div className="bg-gradient-to-b from-[rgba(232,231,231,1)] via-[rgba(232,231,231,0)] to-[rgba(232,231,231,1)]">
        <div className="custom-breakpoint-container flex flex-col items-center space-y-[1.25rem] pt-[3.125rem] pb-[3.3125rem] md:px-[5.25rem] md:py-[4rem] lg:max-w-[41.875rem] lg:px-0">
          {renderCard(selectedTab, siteCopy)}
        </div>
      </div>
    </div>
  );
};

export default CardList;
