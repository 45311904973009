function langSwitcherItems(language, config) {
  const madridRegex = new RegExp(/sapsapphire\/madrid/gi);
  const zurichRegex = new RegExp(/sapsapphire\/zurich/gi);
  const tokyoRegex = new RegExp(/sapsapphire\/tokyo/gi);
  const munichRegex = new RegExp(/sapsapphire\/munich/gi);

  if (config.languageEnglish == null) {
    return [
      {
        value: "default",
        default: true,
        label: "loading",
      },
    ];
  }

  if (madridRegex.test(window.location.pathname.toString()) == true) {
    return [
      {
        value: "es",
        default: true,
        label: config.languageSpanish[language],
      },
      {
        label: config.languageEnglish[language],
        value: "en",
        default: false,
      },
    ];
  }

  if (zurichRegex.test(window.location.pathname.toString()) === true) {
    return [
      {
        value: "de",
        default: true,
        label: config.languageGerman[language],
      },
      {
        label: config.languageEnglish[language],
        value: "en",
        default: false,
      },
    ];
  }

  if (tokyoRegex.test(window.location.pathname.toString()) === true) {
    return [
      {
        value: "ja",
        default: true,
        label: config.languageJapanese[language],
      },
      {
        label: config.languageEnglish[language],
        value: "en",
        default: false,
      },
    ];
  }

  return [];
}

export default langSwitcherItems;
