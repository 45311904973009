import React from "react";
import { Route, BrowserRouter, Switch, useParams } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./model/store";
import SiteMap from "./component/SiteMap";
import './App.css';

function App(props) {

  const { match } = props;

  return (
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          <Route path={`${match.url}/:eventName`} component={SiteMap} />
        </Switch>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
