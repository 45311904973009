import * as cache from "./cacheService";
import axios from "axios";

const cacheKey = (arr) => arr.join(":");

const get = async (args, callback) => {
  const key = cacheKey(args);
  let data = cache.getItem(key);

  if (!data) {
    data = await callback(args);

    if (data) cache.setItem(key, data);
  }

  return data;
};

export const getSiteCopy = async (id, schema, entityName) => {
  const url = `https://phnw31m7qg.execute-api.us-east-1.amazonaws.com/dev/site-copy/${id}?schema=${schema}&entityName=${entityName}`;
  const response =  await axios.get(url,{crossDomain: true});
  return response.data;
};
