const Routes = Object.freeze({
    ROOT: "/",
    AGENDA: "/agenda",
    EVENT_EXPERIENCE: "/event-experience",
    LOGISTICS: "/logistics",
    PARTNERS: "/partners",
    CONTENT: "/content",
    CELEBRATION: "/celebration-night",
    VENUE_FAQ: "/venue-faq",
    INVITE: "/invite",
    MOBILE_APP_LANDING: "/mobile-app"
  });

  export default Routes;