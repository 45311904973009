import React from 'react';

const PartnersPage = () => {
    return (
        <div>
            Partners
        </div>
    );
}

export default PartnersPage;
