import { React, useState } from "react";
import downArrow from "../assets/down-arrow.svg";
import upArrow from "../assets/up-arrow.svg";

import CollapsableData from "./CollapsableData";

const BusinessNeedsCard = ({ gradient, photo, siteCopy, language, index }) => {
  
  //to get around the numbers in the spreadsheet names
  //+1 needed because we start at 0 index
  const sessionShowMoreObjName = `session${index +1}ShowMore`;
  const sessionShowLessObjName = `session${index +1}ShowLess`;

  const [buttonState, setButtonState] = useState("closed");
  const [arrowType, setArrowType] = useState(downArrow);
  const [buttonText, setButtonText] = useState(siteCopy[sessionShowMoreObjName][language]);
  const [classState, setClassState] = useState(
    "lineClamp div1 xl:w-[915px] lg:w-[595px] md:w-[410px] xl:px-[60px] px-[19.5px] pb-0 pt-[30px]"
  );
  const [parentState, setParentState] = useState("lineClampPC");
  const [imageState, setImageState] = useState("basicImage");

  const handleClick = () => {
    if (buttonState === "closed") {
      setArrowType(upArrow);
      setButtonText(siteCopy[sessionShowLessObjName][language]);
      setClassState(
        "lineClampOpen  div1 xl:w-[915px] lg:w-[595px] md:w-[410px] xl:px-[60px] px-[19.5px] pb-0 pt-[30px]"
      );
      setParentState("lineClampPO");
      setButtonState("open");
      setImageState("shiftImage");

    } else {
      setArrowType(downArrow);
      setButtonText(siteCopy[sessionShowMoreObjName][language]);
      setClassState(
        "lineClamp div1 xl:w-[915px] lg:w-[595px] md:w-[410px] xl:px-[60px] px-[19.5px] pb-0 pt-[30px]"
      );
      setParentState("lineClampPC");
      setButtonState("closed");
      setImageState("basicImage");
    }
  };
  return (
    <div className="parent bg-sap-white-FFFFFF shadow-[0px_3px_6px_5px_rgba(0,0,0,0.16)] xl:w-[1360px] lg:w-[1000px] md:w-[700px] w-full">
      <div
        className="
      div1
      xl:h-auto
      xl:w-[145px]
      lg:w-[105px]
      md:w-[90px]
      w-full
      md:h-[314px]
      h-[70px]
      "
      >
        <img
          src={gradient}
          className="
          xl:w-full
          h-full
          lg:w-[105px]
          md:w-[90px]
          w-full
          object-cover"
        />
      </div>
      <div
        className="
      div2
      xl:h-auto
      xl:w-[300px]
      lg:w-[300px]
      md:h-[314px]
      "
      >
        <div className={imageState}
        
        
          style={{
            backgroundImage: `url(${photo})`,
          }}
        ></div>
        {/* <img
          src={photo}
          className="
          xl:w-full
          lg:w-[300px]
          md:w-[200px]
          w-full
          xl:h-full
          md:h-[314px]
          object-cover"
        /> */}
      </div>
      <div className={parentState}>
        <div className={classState}>
          <CollapsableData siteCopy={siteCopy} language={language} />
        </div>
        <div
          className="add-pointer-on-hover flex mt-[30px] self-start md:p-0 ml-[60px] mb-[35px]"
          onClick={handleClick}
        >
          <img src={arrowType} />
          <span className="text-[#037DB9] ml-[15px] text-[1rem] leading-[26px] ">
            {buttonText}
          </span>
        </div>
      </div>
    </div>
  );
};

export default BusinessNeedsCard;
