import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { siteCopyStore } from "../../../app";
import "../../index.css";
import { MenuList, MenuItem, Button } from "@mui/material";
import arrowCaratUp from '../../../app/asset/arrowUp.png';
import arrowCaratDown from '../../../app/asset/arrowDown.png';

const LanguageSwitcherMobile = ({ lang = "en", langItems, ...props }) => {
  const dispatch = useDispatch();
  const { config, language } = useSelector((state) => state.siteCopy);

  const [mobileMenu, setMobileMenu] = React.useState(false);
  const [caratState, setCaratState] = React.useState(arrowCaratUp);

  function buttonLanguage() {
    let response = '';

    switch (lang) {
      case "en":
        response = config.languageEnglish[lang];
        break;
      case "es":
        response = config.languageSpanish["es-Madrid"];
        break;
      case "de":
        response = config.languageGerman["de-Zurich"];
        break;
      case "ja":
        response = config.languageJapanese["ja-Tokyo"];
        break;
    }

    return response
  }

  const handleToggle = () => {
    setMobileMenu((prevOpen) => !prevOpen);
    if (caratState == arrowCaratUp) {
      setCaratState(arrowCaratDown)
    } else {
      setCaratState(arrowCaratUp)
    }
  };

  function langSelecHandler(value, label) {
    dispatch(siteCopyStore.setLanguageLabel(label));

    window.location.href = `${window.location.href.split("?")[0]
      }?lang=${value}`;
  }

  const url = window.location.pathname;
  const newRegex = new RegExp(props.regex)

  if (newRegex.test(url) != true) return <></>;

  let items = langItems

  items = items.map((x) => {
    x.default = window.location.search.includes(`lang=${x.value}`);
    return x;
  });

  return (
    <div
      id="langMenuButton"
      className={`absolute ${props.className ? props.className : ""}`}
    >
      <Button
        className={`normal-case
            w-full h-full 
            rounded-none
            pt-[0.9375rem] pb-[0.9375rem]
            ${props.buttonClassNames ? props.buttonClassNames : ""} `}
        onClick={handleToggle}
      >
        {props.icon && (
          <img
            className="w-[1.5625rem] h-[1.5625rem] mr-[0.625rem]"
            src={props.icon}
          ></img>
        )}
        {buttonLanguage()}
        {/*language !== String.empty ? language : this needs to change  config.languageSpanish[lang]*/}
        <div className='ml-auto'><img className='carat' src={caratState} /></div>
      </Button>
      {mobileMenu && (
        <div className="flex lg:hidden flex-row items-center">
          <MenuList id="langMenuMobile" className={props.menuListStyles}>
            {items.map((x, index) => {
              return (
                <MenuItem
                  onClick={() => langSelecHandler(x.value, x.label)}
                  className={props.menuItemStyles}
                  key={index}
                >
                  {x.label}
                </MenuItem>
              );
            })}
          </MenuList>
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcherMobile;
