export class InvalidEventError extends Error {
  constructor() {
    super("Event does not exist");
    this.name = "InvalidEventError";
  }
}

export class InvalidInvitationCodeError extends Error {
  constructor() {
    super("Invalid invitation code");
    this.name = "InvalidInvitationCodeError";
  }
}

export class ExternalServerError extends Error {
  constructor() {
    super("Something went wrong with somebody elses code"); 
    this.name = "ExternalServerError";
  }
}