import React from "react";
import banner_background from "../assets/Banner-Background.svg";

const AgendaCard = ({ sessionData }) => {
  return (
    <div className="flex flex-col shadow-lg bg-sap-white-FFFFFF w-full md:min-w-[700px] desktop:min-w-[800px]  md:flex-row md:min-h-[4.4375rem]">
      <div
        className="flex justify-center w-full md:items-center md:w-[13.125rem] bg-cover"
        style={{ backgroundImage: `url(${banner_background})` }}
      >
        <p className="text-[1.7281rem] leading-[2.0737rem] mt-[1.3125rem] mb-[0.75rem] md:m-0">
          {sessionData.time && sessionData.time}
        </p>
      </div>
      <div className="flex flex-col items-center pt-[1.25rem] pb-[1.5625rem] px-[0.625rem] space-y-[1.25rem] md:w-full  md:items-start md:max-w-[29.375rem] md:pl-[2.4375rem]">
        {sessionData.sessions &&
          sessionData.sessions.map((titleObj) => {
            let count = 0;
            if (titleObj.time) {
              if (titleObj.speaker && titleObj.speaker.hasValue()) {
                return [
                  <div className="text-center flex flex-col items-center space-y-[0.3125rem] md:items-start md:text-left">
                    <p
                      key={"first" + count}
                      className="m-0 text-[1.44rem] leading-[1.8687rem] md:text-[1.69rem] md:leading-[2.1969rem]"
                      dangerouslySetInnerHTML={{ __html: titleObj.title }}
                    ></p>
                      <p
                        key={"third" + count}
                        className={`m-0 text-[1.44rem] leading-[1.8687rem] md:text-[1.69rem] md:leading-[2.1969rem]`}
                        dangerouslySetInnerHTML={{ __html: titleObj.speaker }}
                      ></p>
                    <p
                      key={"second" + count}
                      className="m-0 text-[1rem] leading-[1.5rem]"
                    >
                      {titleObj.time}
                    </p>
                  </div>,
                ];
              }
              return [
                <div className="text-center flex flex-col items-center space-y-[0.3125rem] md:items-start md:text-left">
                  <p
                    key={"first" + count}
                    className="m-0 text-[1.44rem] leading-[1.8687rem] md:text-[1.69rem] md:leading-[2.1969rem]"
                    dangerouslySetInnerHTML={{ __html: titleObj.title }}
                  ></p>
                  <p
                    key={"second" + count}
                    className="m-0 text-[1rem] leading-[1.5rem]"
                  >
                    {titleObj.time}
                  </p>
                </div>,
              ];
            } else {
              if (titleObj.speaker && titleObj.speaker.hasValue()) {
                return [
                  <p
                    key={"single" + count}
                    className=" text-center m-0 text-[1.44rem] leading-[1.8687rem] md:text-left md:text-[1.69rem] md:leading-[2.1969rem]"
                    dangerouslySetInnerHTML={{ __html: titleObj.title }}
                  ></p>,
                  <p
                    key={"third" + count}
                    className={`m-0 text-[1.44rem] leading-[1.8687rem] md:text-[1.69rem] md:leading-[2.1969rem]`}
                    dangerouslySetInnerHTML={{ __html: titleObj.speaker }}
                  ></p>,
                  <em key={"disclaimer" + count}>
                    <p className="m-0 text-[1rem] leading-[1.5rem]">
                      {titleObj.floorCloseDisclaimer}
                    </p>
                  </em>,
                ];
              }
              return [
                <p
                  key={"single" + count}
                  className=" text-center m-0 text-[1.44rem] leading-[1.8687rem] md:text-left md:text-[1.69rem] md:leading-[2.1969rem]"
                  dangerouslySetInnerHTML={{ __html: titleObj.title }}
                ></p>,
                <em key={"disclaimer" + count}>
                  <p className="m-0 text-[1rem] leading-[1.5rem]">
                    {titleObj.floorCloseDisclaimer}
                  </p>
                </em>,
              ];
            }
            count++;
          })}
      </div>
    </div>
  );
};

export default AgendaCard;
