import React, { forwardRef } from "react";
import Button from "@mui/material/Button";
import "./faqBar.css";

const faqBar = forwardRef(
  (
    {
      covidQuestions_mainText,
      covidQuestions_subText,
      covidQuestions_subText2,
      covidQuestions_subText3,
      covidQuestions_viewFAQBtn,
    },
    ref
  ) => {
    return (
      <>
        <section ref={ref} id="covidSafty" className="darkGradient">
          <div
            className="custom-breakpoint-container text-center flex justify-center flex-col text-white items-center 
            py-[2.5rem] lg:py-[3.125rem]"
          >
            <div
              dangerouslySetInnerHTML={{ __html: covidQuestions_mainText }}
              className="
          text-center
          text-[24px]
          xl:mb-[20px]

          md:mb-[20px]

          mb-[20px]

          md:text-[27.04px]
          w-[16.62rem]
          md:w-[45.5rem]"
            ></div>
            <div
              className="flex flex-col items-center 
              md:mx-[3.875rem] lg:mx-[7.1875rem]"
            >
              <div className="text-left flex flex-col lg:flex-row mb-[1.875rem]">
                <p
                  className="lg:w-1/2 
              mb-[30px] lg:mb-0
              lg:mr-20 w-full"
                >
                  {covidQuestions_subText}
                </p>
                <p
                  className="lg:w-1/2 w-full"
                  dangerouslySetInnerHTML={{ __html: covidQuestions_subText2 }}
                ></p>
              </div>
              <p className="text-left lg:text-center">
                {covidQuestions_subText3}
              </p>
            </div>
            {covidQuestions_viewFAQBtn && <Button
              className="
              hidden

              xl:mt-[27px]
              xl:mb-[50px]

              md:mt-[27px]
              md:mb-[50px]

              mb-[50px]
            

              border-[#ffffff]
              text-white
              sap-btn
              border-solid"
            >
              {covidQuestions_viewFAQBtn}
            </Button>
            }
          </div>
        </section>
      </>
    );
  }
);

export default faqBar;
