import React from "react";
import Button from "@mui/material/Button";
import MuiAccordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import "./questions.css";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  // "& .MuiButtonBase-root > .MuiAccordionSummary-content": {
  //   borderBottom: "0.063rem solid black",
  // },
  "& .MuiAccordion-region > .MuiAccordionSummary-content": {
    borderBottom: "0.063rem solid black",
  },

  "& .MuiAccordionSummary-expandIconWrapper": {},
}));

const questions = ({siteCopy, handleCovidClick}) => {
  const [expanded, setExpanded] = React.useState('');
  const [subExpanded, setSubExpanded] = React.useState('');

  

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSubChange = (panel) => (event, newExpanded) => {
    if(panel == "panel3Sub0"){
      const covidId = document.querySelector('#covid-click');
      covidId.classList.add('cursor-pointer');
      covidId.addEventListener("click", handleCovidClick);
      handleCovidClick();
    }
    setSubExpanded(newExpanded ? panel : false);
  };
  return (
    <>
      <section id="questions" className="flex justify-center flex-col items-center py-[3.125rem] xl:py-[7.5rem] custom-breakpoint-container">
        <div
          className="questions__title"
          dangerouslySetInnerHTML={{ __html: siteCopy && siteCopy.faq_MainTitle }}
        >
        </div>
        <div
          className="
            flex
            justify-center
            xl:gap-x-[43px]
            items-baseline
            lg:content-center
            xl:flex-row
            flex-col
            xl:w-[100%]
            lg:w-[100%]
            w-[100%]
          "
        >
          <div className="sap__accordion w-[90%] md:w-[80%] lg:w-[80%] xl:w-[36.5625rem] xs:w-[282px]">
            {siteCopy && siteCopy.itemArray.map((accordionObj, index) => {
              if(index < 2){
              return (
                <div key={index} className="accord__wrapper">
                  <Accordion expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      className="accor__title">
                      <Typography
                        sx={{ flexShrink: 1 }}
                        dangerouslySetInnerHTML={{ __html: accordionObj[`faq_category${index + 1}Title`] }}
                      ></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        Array.from(Array(accordionObj.itemCount).keys()).map(itemCount => {
                          return (
                            <div key={`${index}_${itemCount}`}>
                              <Accordion expanded={subExpanded === `panel${index}Sub${itemCount}`} onChange={handleSubChange(`panel${index}Sub${itemCount}`)}>
                                <AccordionSummary
                                  aria-controls={`panel${index}SubPanel${itemCount}bh-content`}
                                  id={`panel${index}SubPanel${itemCount}bh-header`}
                                >
                                  <Typography
                                    sx={{ flexShrink: 1 }}
                                    dangerouslySetInnerHTML={{
                                      __html: accordionObj[`faq_category${index + 1}Item${itemCount + 1}Title`],
                                    }}
                                    className="accor__subtitle"
                                  ></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography
                                    dangerouslySetInnerHTML={{
                                      __html: accordionObj[`faq_category${index + 1}Item${itemCount + 1}Description`],
                                    }}
                                  ></Typography>
                                </AccordionDetails>
                              </Accordion></div>
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            })}
          </div>
          <div className="sap__accordion w-[90%] md:w-[80%] lg:w-[80%] xl:w-[36.5625rem] xs:w-[282px]">
            {siteCopy && siteCopy.itemArray.map((accordionObj, index) => {
              if(index >= 2){
              return (
                <div key={index} className="accord__wrapper">
                  <Accordion expanded={expanded === `panel${index + 1}`} onChange={handleChange(`panel${index + 1}`)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}bh-content`}
                      id={`panel${index}bh-header`}
                      className="accor__title">
                      <Typography
                        sx={{ flexShrink: 1 }}
                        dangerouslySetInnerHTML={{ __html: accordionObj[`faq_category${index + 1}Title`] }}
                      ></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      {
                        Array.from(Array(accordionObj.itemCount).keys()).map(itemCount => {
                          return (
                            <div key={`${index}_${itemCount}`}>
                              <Accordion expanded={subExpanded === `panel${index}Sub${itemCount}`} onChange={handleSubChange(`panel${index}Sub${itemCount}`)}>
                                <AccordionSummary
                                  aria-controls={`panel${index}SubPanel${itemCount}bh-content`}
                                  id={`panel${index}SubPanel${itemCount}bh-header`}
                                >
                                  <Typography
                                    sx={{ flexShrink: 1 }}
                                    dangerouslySetInnerHTML={{
                                      __html: accordionObj[`faq_category${index + 1}Item${itemCount + 1}Title`],
                                    }}
                                    className="accor__subtitle "
                                  ></Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography
                                    dangerouslySetInnerHTML={{
                                      __html: accordionObj[`faq_category${index + 1}Item${itemCount + 1}Description`],
                                    }}
                                  ></Typography>
                                </AccordionDetails>
                              </Accordion></div>
                          )
                        })
                      }
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            })}
          </div>
        </div>
      </section>
    </>
  );
};
export default questions;
