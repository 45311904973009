import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMount } from "react-use";
import { siteCopyStore } from "../app";
import ViewSessionCatalog from "../app/component/ViewSessionCatalog";
import SimpleHeroBanner from "../app/component/SimpleHeroBanner";
import BusinessNeeds from "./components/BusinessNeeds";
import Experiences from "./components/Experiences";
import ScrollToTopSection from "../app/component/scrollToTopSection";

import "./css.css";

const ContentPage = ({ language = "en" }) => {
  const dispatch = useDispatch();
  const { contentCopy } = useSelector((state) => state.siteCopy);
 
  useMount(async () => {
    await dispatch(siteCopyStore.content);
  });

  return (
    <div>
      <SimpleHeroBanner
        siteCopy={contentCopy.hero && contentCopy.hero.mainText[language]}
        //doing this because other pages use the hero img this way
        buttonText={contentCopy.hero && contentCopy.hero.buttonText[language]}
        buttonURL={contentCopy.hero && contentCopy.hero.buttonURL[language]}
      />
      <BusinessNeeds siteCopy={contentCopy.schedule} language={language} />
      <Experiences
        siteCopy={contentCopy.differentExperiences}
        language={language}
      />
      {/* <ViewSessionCatalog
        mainText={
          contentCopy.viewSessionCatalog &&
          contentCopy.viewSessionCatalog.mainText
        }
        subText={
          contentCopy.viewSessionCatalog &&
          contentCopy.viewSessionCatalog.subText
        }
        btnText={
          contentCopy.viewSessionCatalog &&
          contentCopy.viewSessionCatalog.goToCatalogBtn
        }
        btnUrl={
          contentCopy.viewSessionCatalog &&
          contentCopy.viewSessionCatalog.goToCatalogBtnUrl
        }
        language={language}
      /> */}
      <ScrollToTopSection />
    </div>
  );
};

export default ContentPage;
