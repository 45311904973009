import * as mapper from "./mapper";
import { service, cache } from "../../eclipse-ux";

const googleSheetId = "17K1llP8CmiDeW8dXYhyGBPemDlm5suLuTgGyJbe_pOs";
const depth1Schema = "depth1";
const depth2Schema = "depth2";

export const Actions = Object.freeze({
  CONFIG: "site-copy/config",
  NAVBAR: "site-copy/navbar",
  FOOTER: "site-copy/footer",
  HOME: "site-copy/home",
  LANGUAGE: "site-copy/language",
  AGENDA: "site-copy/agenda",
  CONTENT: "site-copy/content",
  EVENT_EXPERIENCE: "site-copy/event-experience",
  PARTNER_SHOWCASE: "site-copy/partner-showcase",
  VENUE_FAQ: "site-copy/venue-faq",
  INVITE: "site-copy/invite",
  CELEBRATION_NIGHT: "site-copy/celebration-night",
  MOBILE_APP_LANDING: "site-copy/mobile-app-landing",
});

export const reducer = (
  state = {
    config: {},
    navbarCopy: {},
    footerCopy: {},
    homeCopy: {},
    languageCopy: [],
    agendaCopy: {},
    contentCopy: {},
    eventExperienceCopy: {},
    partnerShowcaseCopy: {},
    celebrationNightCopy: {},
    venueFaqCopy: {},
    invitePageCopy: {},
    mobileAppLandingCopy: {},
    language: "",
  },
  action
) => {
  switch (action.type) {
    case Actions.CONFIG:
      return { ...state, config: action.config };
    case Actions.NAVBAR:
      return { ...state, navbarCopy: action.navbar };
    case Actions.FOOTER:
      return { ...state, footerCopy: action.footer };
    case Actions.HOME:
      return { ...state, homeCopy: action.home };
    case Actions.AGENDA:
      return { ...state, agendaCopy: action.agenda };
    case Actions.CONTENT:
      return { ...state, contentCopy: action.content };
    case Actions.EVENT_EXPERIENCE:
      return { ...state, eventExperienceCopy: action.eventExperience };
    case Actions.PARTNER_SHOWCASE:
      return { ...state, partnerShowcaseCopy: action.partnerShowcase };
    case Actions.VENUE_FAQ:
      return { ...state, venueFaqCopy: action.venueFaq };
    case Actions.CELEBRATION_NIGHT:
      return { ...state, celebrationNightCopy: action.celebrationNight };
    case Actions.MOBILE_APP_LANDING:
      return { ...state, mobileAppLandingCopy: action.mobileAppLanding };
    case Actions.INVITE:
      return { ...state, invitePageCopy: action.invite };
    case Actions.LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
};

export const setLanguageLabel = (language) => (dispatch, getState) => {
  cache.setItem("siteCopy", { language });
  dispatch({ type: Actions.LANGUAGE, language });
};

export const shell = async (dispatch, getState) => {
  const configCopy = await service.getSiteCopy(
    googleSheetId,
    depth1Schema,
    "config"
  );

  const { config } = mapper.fromConfigEntity(configCopy);

  const navbarCopy = await service.getSiteCopy(
    googleSheetId,
    depth1Schema,
    "navbar"
  );

  const { navbar } = mapper.fromNavbarEntity(navbarCopy);

  const footer = await service.getSiteCopy(
    googleSheetId,
    depth1Schema,
    "footer"
  );

  const lang = cache.getItem("siteCopy");
  dispatch({
    type: Actions.LANGUAGE,
    language: lang ? lang.language : String.empty,
  });
  dispatch({ type: Actions.CONFIG, config });
  dispatch({ type: Actions.NAVBAR, navbar });
  dispatch({ type: Actions.FOOTER, footer: mapper.fromFooterEntity(footer) });
};

export const home = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "homePage"
  );

  const { homePage } = mapper.fromHomePageEntity(copy);

  dispatch({ type: Actions.HOME, home: homePage });
};

export const agenda = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "agendaPage"
  );

  const { agendaPage } = mapper.fromAgendaPageEntity(copy);

  dispatch({ type: Actions.AGENDA, agenda: agendaPage });
};

export const content = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "contentPage"
  );

  const { contentPage } = mapper.fromContentPageEntity(copy);

  dispatch({ type: Actions.CONTENT, content: contentPage });
};

export const eventExperience = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "eventExperiencePage"
  );

  const { eventExperiencePage } = mapper.fromEventExperiencePageEntity(copy);

  dispatch({
    type: Actions.EVENT_EXPERIENCE,
    eventExperience: eventExperiencePage,
  });
};

export const partnerShowcase = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "partnerShowcasePage"
  );

  const { partnerShowcasePage } = mapper.fromPartnerShowcasePageEntity(copy);

  dispatch({
    type: Actions.PARTNER_SHOWCASE,
    partnerShowcase: partnerShowcasePage,
  });
};

export const venueFaq = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "venueFaqPage"
  );

  const { venueFaqPage } = mapper.fromVenueFaqPageEntity(copy);

  dispatch({ type: Actions.VENUE_FAQ, venueFaq: venueFaqPage });
};

export const celebrationNight = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "celebrationNightPage"
  );

  const { celebrationNightPage } = mapper.fromCelebrationNightPageEntity(copy);

  dispatch({
    type: Actions.CELEBRATION_NIGHT,
    celebrationNight: celebrationNightPage,
  });
};

export const mobileAppLanding = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth2Schema,
    "mobileAppLandingPage"
  );

  const { mobileAppLandingPage } = mapper.fromMobileAppLandingPageEntity(copy);

  dispatch({
    type: Actions.MOBILE_APP_LANDING,
    mobileAppLanding: mobileAppLandingPage,
  });
};

export const invite = async (dispatch, getState) => {
  const copy = await service.getSiteCopy(
    googleSheetId,
    depth1Schema,
    "invitePage"
  );

  const { invitePage } = mapper.fromInvitePageEntity(copy);

  dispatch({ type: Actions.INVITE, invite: invitePage });
};
