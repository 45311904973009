export const getCookie = (name) => {
    const cookie = document.cookie;
    const prefix = name + "=";
    let begin = cookie.indexOf("; " + prefix);
    let end;
    if (begin == -1) {
      begin = cookie.indexOf(prefix);
      if (begin != 0) return null;
    } else {
      begin += 2;
       end = document.cookie.indexOf(";", begin);
      if (end == -1) {
        end = cookie.length;
      }
    }
    return decodeURI(cookie.substring(begin + prefix.length, end));
  };